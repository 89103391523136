import React from 'react';
import styled from 'styled-components';

import Form from "./Components/Molecules/Form"


const Body = styled.div`
    width: 100%;
    padding-top:65px;
    bottom:0;
    left: 0;
    right:0;
    background: white;
`;

class AppForm extends React.Component{
  constructor()
  {
    super();
    this.state = {
      confirm: false,
      data: {}
    }
  }
  render()
  {
    return(
      <Body>
        <Form />
      </Body>
    )
  }
}
export default AppForm