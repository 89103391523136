
import React, { Component } from 'react';
import styled from 'styled-components';

import {  Link } from 'react-router-dom';

	

const Empty = (props)=> {


  class Loading extends Component {
    render()
    {
      return (
        <Container>
          <Title>Solicita tu primer adelanto</Title>
          <Link to="/create-new">
            <Button>Empezar</Button>
          </Link>
        </Container>
    	)
    }
  }
  return (<Loading/>)

                
}

export default Empty




const Img = styled.img`
  background: transparent; 
  
  
     @media (max-width: 700px) {
       
     width: 250px;
    
    
  }
  `;

const Container = styled.div`


  display: flex;
  width: 100%;
  text-align:center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: transparent;
  position: relative;
  
 
${props=>props.theme.default.utils.centerContent}
position: relative;
`;

const Title = styled.h1`
  font-family: 'Rubik', sans-serif;
   font-weight: 400;
   font-size: 14px;
   color: #898989;
   margin: 1rem auto;
`;
const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
   font-family: 'Rubik', sans-serif;
   font-weight: 400;
  border:0;

 
`;
