
import firebase from "firebase";



export async function createUsuario(data){


  const fecha =  Date.now()
	
  await firebase.database().ref(`profile/${data.id}`).set(
    { 
	    name: data.name,
	    apellido: data.apellido,
	    mail:data.mail,
	    telefono:data.telefono,
	    id: data.id,
	    fecha
    }).catch((err)=>{
	    alert("Hubo un error guardando los datos del perfil, porfavor verifique la coniguracion antes de realizar una solicitud.")  
  })
}

export async function updateUsuario(data, userId = null ){

  if(!userId && firebase.auth().currentUser)
    userId = firebase.auth().currentUser.uid
	
  await firebase.database().ref(`profile/${userId}`).update(
    {...data,
  
    }).then((res)=>{
    // this.setState({success: true, confirm: false})
  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.");
    //Show Error mesage
  })
}