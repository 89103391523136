import React, { Component } from 'react';
import styled from 'styled-components';
import {  Link, withRouter} from 'react-router-dom';
import Loading from "Components/Molecules/Loading";
import Payment from "./Components/Molecules/Payment" ;
import {getFullSolicitud} from "Files/General/DatabaseQueries/Get/Solicitudes";
import successImg from "static/img/success.png"
import FileModal from "Components/Molecules/FileModal";

class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      currentFile:null
    }
  }

  async componentDidMount()
  {
    setTimeout(this.fetchAgain.bind(this), 2000)
 
  }

  async fetchAgain(){
    const solicitud = await getFullSolicitud(this.props.id)
    this.setState({solicitud, loading: false})
  }


  render()
  {

    const close = this.props.close
  
  
    if(this.state.loading){
      return(
        <Loading text="Obteniendo datos" />
      )
    }
    
  
    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>

        <Card>


          <NavBar onClick={this.fetchAgain.bind(this)}>
            <NavTextContainer>
              <Title style={{textAlign: "left"}}>
                Folio #{this.state.solicitud.folio}
              </Title>
      
            </NavTextContainer>
          
          </NavBar>
          <Container>
            <Img src={successImg}  />

            <Title>
              Envío de Solicitud #{this.state.solicitud.folio} exitoso
            </Title>

            <Subtitle>
              Estamos validando tus datos; esto puede tardar unos minutos. Nosotros te notificaremos en cuanto este listo, tu tranquilo.
            </Subtitle>
 
            <Payment 
              solicitud={this.state.solicitud}
              setCurrentFile = {(file, title)=>{
                this.setState({currentFile: {
                  file,
                  title
                }})
              }} 
            />

            <ButtonContainer>

              <Link to="/dashboard">
                <Button shadow>Volver al inicio </Button>
              </Link>

            </ButtonContainer>

          </Container>

          {this.state.currentFile && 
          <FileModal  
            close = {()=>{this.setState({currentFile: null})}}
            {...this.state.currentFile}/>}
        </Card>
      </FullScreen>
    );
  }
}
    
export default withRouter(Modal)




const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.8);
  padding: 0rem;
  padding-top:1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 50%;
  padding: 1rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;


const NavBar = styled.div`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;

const Subtitle = styled.h4`
font-weight: 400;
text-align: center;
color: #5F6469;
font-size: 16px;
margin: 0.5rem 0;  

`;

const NavTextContainer = styled.div`
  width: 90%;
  text-align: left;

`;

const IconContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const Icon = styled.i`
  vertical-align: bottom;
  cursor:pointer;
`;

const Img = styled.img`
width: 150px;
margin: 1rem auto;
text-align: center;

`;


const Container = styled.div`
  width:80%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;


const ContainerBox = styled.div`
  width:100%;
  border: 1px gray solid;
  border-radius: 7px;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 2.3rem;
  text-align: center;

`;

const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => props.theme.colors.green};
  color: white;
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:0;

 
`;