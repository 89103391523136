import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Loading from "Components/Molecules/Loading"
import firebase from "firebase"
import {Input} from "Views/Solicitud/Components/Molecules/Form/Components/Molecules/Inputs"
import {getUsuario} from "Files/General/DatabaseQueries/Get/Usuarios"

class User extends Component {

  constructor(props)
  {
    super(props);
    this.state = {
      
      loading: true,
        
      profile:{
        name:"",
        apellido:"",
        telefono:"",
        mail:""
      },
       
      staging: false,
        
    }
    
  }

  async componentDidMount(){
    await getUsuario(
      firebase.auth().currentUser.uid,
      (data) => {    
        this.setState({profile: data, loading: false});
      }
    )
  }
   
  componentDidUpdate()
  {
   
  }
   
  save()
  {
    firebase.database().ref(`profile/${firebase.auth().currentUser.uid}`).update({ 
      name: this.state.profile.name,
      apellido: this.state.profile.apellido,
    }).then(()=>{this.setState({staging: false})})
      .catch((err)=>{
            
        alert("Alerta, hubo un error.")       
      })
  }
 
  render() {
    if(this.state.loading)
      return <Loading />
    return(  
      <Body>
        
        <Card>
          <h2>
            Datos de usuario
          </h2>
          <br/>

          <Input
            onChange={(e)=>{this.setState({profile:{...this.state.profile, name: e}, staging: true })}}
            value={this.state.profile.name}
            placeholder="Nombre"/>

          <Input
            
            onChange={(e)=>{this.setState({profile:{...this.state.profile, apellido: e}, staging: true })}}
            value={this.state.profile.apellido}
            placeholder="Apellido"/>

          <Input
            disabled
            onChange={(e)=>{}}
            value={this.state.profile.telefono}
            placeholder="Número"/>


          <Input
            disabled
            onChange={(e)=>{}}
            value={this.state.profile.mail}
            placeholder="Correo"/>
         
          <br/>
          <br/>
          <br/>
          <Button
            disabled = {!this.state.staging}
            onClick={()=>{this.save()}}>Guardar cambios</Button>
        </Card>
      </Body>
          
    )
  }
}




export default withRouter(User)


const Body = styled.div`
  width: 90%;
  padding: 1rem;
  margin: 0 auto;
  overflow: none;
`;

const Card = styled.div`
  width: 50%;
  height: auto;
  background: white;
  border-radius: 7px;
  padding: 2rem;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.2);
  margin: 1rem auto;
  h1,h2{
    text-align: left;
    font-weight: 500;
  }
  i{
    vertical-align: bottom;
  }
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 7px;
  overflow: hidden;
  background: ${props => !props.disabled?props.theme.colors.green: "gray"};
  color: ${props => !props.disabled?"white": "rgba(0,0,0,0.3)"};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:${props => props.red?"solid 1px red": "0"};
  @media (max-width: 700px) {
    margin: 1rem auto;
    display: block;
  } 
`;

export const InputStyles = styled.input`
    padding:  1rem;
    font-size: 18px;
    margin: 0.5rem auto;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    position: relative;
    background: white;
    color: #212121;
    border: 1px solid #E5E5E5;  
`;