import React, { Component} from 'react';
import styled, {keyframes} from 'styled-components';



const _ = require('lodash');

export const Card = styled.div`

  width: 100%;
  margin: 2rem auto;
  ${props=> props.moveTop && `margin: 0 auto;`}

`;



export const slideLeft = keyframes`

   0% {
    
            transform: translateX(100px);
  }
  100% {
    
            transform: translateX(0);
  }

`;

export const CardContent = styled.div`
 

    padding:  2rem;
  ${props=> props.moveTop && `padding: 0rem 2rem`}
animation: ${slideLeft}  0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
 
 @media (max-width: 700px)
        {
         animation: none;
         
        }


`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 45px;
  color: #202124;
  text-align: center;

`;

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 16px;
  color: #5F6469;
  text-align: center;
    a{
    color: #00C853;

  }
`;


export const Item = ({children, title, subtitle,moveTop})=> (
  <Card
    moveTop= {moveTop}
    className="slide-left"
  >
    <CardContent  moveTop= {moveTop}> 
      <Title>
        {title}
      </Title>
      <Subtitle>
        {subtitle}
      </Subtitle>
      {children}
    </CardContent>
  </Card>)
