import React, { Component } from 'react';
import styled from 'styled-components';

import {withRouter} from 'react-router-dom';

import Loading from "Components/Molecules/Loading";
import Payment from "./Components/Molecules/Payment" ;
import {getFullSolicitud} from "Files/General/DatabaseQueries/Get/Solicitudes";

import RoundPublic from "react-md-icon/dist/BaselineTimelapse";
import BaselineCheck from "react-md-icon/dist/BaselineCheck";
import BaselineCancel from "react-md-icon/dist/BaselineCancel";
import BaselinePayment from "react-md-icon/dist/BaselinePayment";
import BaselineMonetizationOn from "react-md-icon/dist/BaselineMonetizationOn";

import FileModal from "Components/Molecules/FileModal";





import Close from "react-md-icon/dist/BaselineClose";
const ICONS = [
  ()=><BaselineCancel/>,
  ()=><RoundPublic/>,
  ()=><BaselineCheck/>,
  ()=><RoundPublic/>,
  ()=><BaselinePayment/>,
  ()=><BaselineMonetizationOn/>,
]

const ESTADOS = [
  "Rechazado", // 0 or undefined is cancel
  "En Espera de confirmación", //1
  "Aceptada",
  "En proceso de depósito",
  "Adelanto depositado",
  "Adelanto pagado",
]
const COLORS = [
  "red", // 0 or undefined is cancel
  "#FF8F00", //1
  "green",
  "blue",
  "green",
  "gray",
]

export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
   font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}

${IconCirlce}{ 
  height: 30px;
  width: 30px;
}
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;

const Input= styled.input`
  width:100%;
  margin: 2rem auto;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  color: rgba(0,0,0,0.2);
  padding: 1rem auto;
  font-size: 14px;
`;

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
padding-top:1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 60%;
  padding: 1rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;


const NavBar = styled.div`
 position: absolute;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;
  //box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;

const Subtitle = styled.h4`
 font-weight: 500;
  text-align: left;
  color: #5F6469;
  font-size: 16px;
  margin: 0 0.5rem; 

`;

const NavTextContainer = styled.div`
  width: 100%;
  text-align: left;

`;

const IconContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const Icon = styled.i`
  vertical-align: bottom;
  cursor:pointer;
`;

const Img = styled.img`
width: 150px;
margin: 1rem auto;
text-align: center;

`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;


const ContainerBox = styled.div`
  width:100%;
  border: 1px gray solid;
  border-radius: 7px;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 2.3rem;
  text-align: center;

`;

const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => props.theme.colors.green};
  color: white;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:0;

 
`;





const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;



const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}

  
   @media (max-width: 700px) {
          width: 100%;
         }
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
               
           }
          
          
        `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}

`;



const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;

const Subtitle2 = styled.div`
font-weight: 500;
text-align: left;
color: #5F6469;
font-size: 16px;
margin: 0 0.5rem;


`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;

const _ = require('lodash');



class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      currentFile: null,
    }
  }

 

  async componentDidMount(){
    

    const solicitud = await getFullSolicitud(this.props.id)

    this.setState({solicitud, loading: false})
  }


  render()
  {

    const close = this.props.close//?this.props.close:this.props.hideOnClick;

  
    if(this.state.loading){
      return(
        <Loading text="Obteniendo datos" />
      )
    }
    
    const CurrentIcon = ICONS[this.state.solicitud.status]
    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>

        <Card>


          <NavBar>
            <Content>
              <AlignStart>
                <Item2
                  hide
                  style={{width:"200px"}}>
      
                  <Title2>
           
                    Folio #{this.state.solicitud.folio}
                  </Title2>
          
        
                </Item2>

                <Item2
                  hide
                  style={{width:"10px"}}>
                  <Separator />
                </Item2>
        
    
                <Item2 style={{width:"500px"}}>
                  <PrivacyTab style={{cursor:"not-allowed"}}> 

                    <PrivacyTabContainer>
                      <IconCirlce>
                        <CurrentIcon /> 
                      </IconCirlce> 
                      <Subtitle style={{color:COLORS[this.state.solicitud.status]}}> {ESTADOS[this.state.solicitud.status]}
                      </Subtitle>
                    </PrivacyTabContainer>
                  </PrivacyTab>
                </Item2>
              </AlignStart>
    
              <AlignEnd>
      
              </AlignEnd>

            </Content>
            <Close
              onClick={()=>close()} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
          
                "zIndex": "99999"


              }}/>

          </NavBar>
          <Container>

            <Payment 
              solicitud={this.state.solicitud}
              setCurrentFile = {(file, title)=>{this.setState({currentFile: {
                file,
                title
              }})}} 
            />

            <ButtonContainer>

            </ButtonContainer>
          </Container>


          {this.state.currentFile && 
          <FileModal  
            close = {()=>{this.setState({currentFile: null})}}
            {...this.state.currentFile}/>}


        </Card>
      </FullScreen>
    );
  }
}


      
export default withRouter(Modal)