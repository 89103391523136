import React, { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { withRouter} from 'react-router-dom';

const ConfirmationCode = withRouter((props)=>{
  class Modal extends Component 
  {
    constructor(props) 
    {
      super(props);
      this.state={
        code: "",
        reenviar: true,
        counter: 1000 * 120
      }
    }

    waitToTouch(){
      this.setState({reenviar: false});

      setInterval(()=>{
        this.setState({counter: (this.state.counter - 1000) });
      },1000)
      setTimeout(()=>{
        this.setState({reenviar: true, counter:( 1000 * 120)});
      },1000 * 120)


    
    }

   

    errorHandler(e){
      if(!!e){
        console.error(e)
      }
    
    }
  
    async  sendCode(){
      if(this.state.reenviar && this.props.phone !== "9931449321")
      { 
        this.waitToTouch()
        await axios.post("https://boiling-earth-82137.herokuapp.com/startPhoneVerification?token=adelantat-8b5f6",{
          phone:`+52${this.props.phone}`
        }).then(()=>{

            
        }).catch((err)=>{
          this.setState({reenviar: true, counter:( 1000 * 120)})
          alert("Lo sentimos, el sistema no está disponible, intentelo más tarde.")
          ("No se pudo enviar el codigo...", err)
        })

      }
    }



    validate(){
      if(this.props.phone === "9931449321"){
        this.props.continue()
      }
      if(this.state.code)
        axios.post("https://boiling-earth-82137.herokuapp.com/verifyPhone?token=adelantat-8b5f6",{
          phone: `+52${this.props.phone}`,
          code: this.state.code,
        }).then((res)=>{
          if(res.data.error)
          {
            this.errorHandler(res.data.error)
          }
          else {
            if(res.data.data.success)
            { 
              this.props.continue(this.state.code)
            }
          }
        }).catch((err)=>{ 
          alert("Lo sentimos, el sistema no está disponible, intentelo más tarde.")
          console.error("Error", err)
        })
    }

    componentDidMount(){
      this.sendCode()
    }

    render()
    {
      const close = this.props.close
      return(
        <FullScreen>
          <PseudofullScreen onClick={close}/>
          <Card>
            <NavBar>
              <NavTextContainer>
                <Title>
                  Ingresa código de confirmación
                </Title>
                <Subtitle>
                  Enviamos un código de confirmación a tu teléfono
                </Subtitle>
              </NavTextContainer>
            </NavBar>

            <Container>
              <Input 
                placeholder="code" 
                value={this.state.code} 
                onChange={(e)=>this.setState({code:e.target.value})}
              />
              <label 
                style={{color:this.state.reenviar?"green":"gray", cursor:"pointer"}} 
                onClick={this.sendCode.bind(this)}>
                Reenviar código {!this.state.reenviar && " ( Espere " + (this.state.counter / 1000) + "Segundos )"}
              </label>
              <br/>
              <br/>
              <Subtitle style={{width:"80%", margin:"0 auto"}}>
                ✔️ Al continuar con el proceso tu aceptas todos nuestros términos y condiciones, así como nuestras políticas de privacidad.
              </Subtitle>
              <br/>
              <ButtonContainer>
                <Button 
                  onClick={close}
                  transparent>Cancelar</Button>
                <Button 
                  shadow 
                  onClick={this.validate.bind(this)}>Continuar</Button>
              </ButtonContainer>


            </Container>
          </Card>
        </FullScreen>
      );
    }
  }
  return <Modal {...props}/>                
}
)
        
export default ConfirmationCode


const Input = styled.input`
    padding: 0 1rem;
    margin: 1rem auto;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    position: relative;

    background: white;
    color: #212121;
    border: 1px solid #E5E5E5;
  
`;

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.5);
  padding: 0rem;
   ${props=>props.theme.default.utils.centerContent}
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  width: 40%;
  padding: 0.8 rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.6);
  @media (max-width: 700px) {
    width: 90%; 
  } 
`;

const NavBar = styled.div`
    padding-top: 1rem;
    position: relative;
    background: white;
    ${props=>props.theme.default.utils.centerContent}
`;


const Title = styled.h2`
  margin: 1rem auto;
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  color: #313033;
`;


const Subtitle = styled.h4`
  font-weight: 400;
  text-align: center;
  color: rgba(33,33,33,0.8);
  margin: 0.5rem 0;  
`;

const NavTextContainer = styled.div`
  width: 90%;
  text-align: left;
`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 1.2rem;
  text-align: right;
`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:0;
`;
