
import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";

		 //Imports.
//{getImports(data.component.imports)}
   

		

const _ = require('lodash');

const Loading = withRouter((props)=> {


  //Code
  const Card = styled.div`

		width: 100%;
		height: 100vh;
		position: fixed;
		top:0;
		left:0;
		background: rgba(255,255,255,0.7);
		color: black;
    font-weight: 400;
    font-size: 22px;
		${props=>props.theme.default.utils.centerContent}
	z-index: 999999;
		.lds-ripple {
		
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid ${props=>props.theme.colors.green};
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

`;


  return(<Card><div className="lds-ripple"><div></div><div></div></div>
 
    <div>{ props.text? props.text: "Cargando..."}</div>
  </Card>)
  class MyComponent extends React.Component
  {
    constructor(props){
      super(props);
      this.state = {

      }
    }
    render(){
      return(<h1>Statefull</h1>)
    }

  }

  return <h1>Im a component.</h1>
                
}
)

        


export default Loading