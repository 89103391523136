
import firebase from "firebase";
import {updateUsuario} from "../Usuarios"
import {createDispersion} from "../Dispersiones"
const uuid = require('uuid');


export async function crearConnectSolicitud(solicitud){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]

  firebase.database().ref(`connectSolicitudes/${solicitud.id}`).set(
    {
      id:solicitud.id,
      cobroId:"",
      dispersionesId:"",
      autorizacionId:"",
      analisisId:"",

    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error con la conexion, intenta de nuevo.")
    //Show Error mesage
  })
}

export async function updateConnectSolicitud(solicitud)
{
  firebase.database().ref(`connectSolicitudes/${solicitud.id}`)
    .update({...solicitud}).then((res)=>{
    }).catch((err)=>{
      alert("Hubo un error con la conexion, intenta de nuevo.")
    })
}

export async function createSolicitud(data, callback=null){

  const id = uuid();

  const {
    
    usuario =firebase.auth().currentUser.uid,

    banco = "",
    clabe = "",
    convenio = "",
    cuenta = "",


 

    //This is just metadata
    files_adress = "",
    files_id = "",
    files_nomina = "",

    //This is just metadata
    monto = "0",
    montoAprobado = "0",
    status = 1,
    empresa="",
    telEmpresa="",
    referencia="",
    telReferencia=""
    
  } = data 

 
  await updateUsuario({
    banco,
    clabe,
    convenio,
    cuenta,
    files_adress,
    files_id,
    files_nomina,
    empresa,
    telEmpresa,
    referencia,
    telReferencia,
    ultimaSolicitud: id,
        
  })

  //await crearConnectSolicitud({id})
    
  // (this.state.data, this.state.profile)
  //We update the state
     
  firebase.database().ref(`solicitudes/${id}`).set(
    {

      fecha:Date.now(),
      usuario,
      nombreUsuario:`${data.name} ${data.apellido}`,
      id,
      banco,
      clabe,
      cuenta,
      files_adress,
      files_id,
      files_nomina,
      monto,
      montoAprobado,
      status,
      empresa,
      telEmpresa,
      referencia,
      telReferencia,
      folio: Math.floor((Math.random() * 99999) + 1) + Math.floor((Math.random() * 99) + 1),
      action: "NEW_SOLICITUD"

    }).then((res)=>{

    if(callback)
      callback({response:res, id})
            
  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
    //Show Error mesage
  })
}

export async function updateSolicitud(data, newConnect = null){

  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
  firebase.database().ref(`solicitudes/${data.id}`).update(
    {...data,
      fechaDeActualizacion: Date.now(),
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error, intenta de nuevo.")
    //Show Error mesage
  })

  //Si al actualizar hay nueva informacion de conextion entonces actualizamos el conector.
  if(newConnect)
    updateConnectSolicitud(newConnect)
}


export async function cancelarSolicitud(id){
  //Crea nueva propuesta
  //crearAnalisis({});
    
  updateSolicitud({id,status:0});
  //crearAutorizacion(); -Este es un simple log de la autorizacion
}


export async function aceptarSolicitud(data){
  //[Solicitud,Usuario, analisis, montoAceptado]
  //Crea nueva propuesta
  // await crearAnalisis({montoAprobado:"2400"});
  await createDispersion({...data});
  await updateSolicitud({id:data.id, status:3, action: "NEW_STATUS"})
  // await crearAutorizacion(data); 
}