import React, { Component } from 'react';

const scrollToTopOnMount = (PassedCompoenet)=>
  class App extends Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
      return <PassedCompoenet 
        {...this.props}
    		/>
    }
  }
export default scrollToTopOnMount