import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';


export const Form = styled.div`
  width: 60%;
  background: white;
  margin: 0 auto;

  @media (max-width: 700px) 
  {
          width: 90%;
  }
  margin-bottom: 5rem;
`;



export const Monto = styled.div`
  width: 24%;
  display: inline-block;
  ${props=>props.theme.default.centerContent}
  padding:  1rem;
  border-radius: 7px;
  background: white;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.2);
  font-size: 40px;
  font-weight: 500;
     @media (max-width: 700px) {
          width: 100%;
          position: relative;
          margin: 0 auto;
          text-align: center;
         }

`;

export const Row = styled.div`
  width: 100%;
  ${props=>props.theme.default.utils.rowContent}
     @media (max-width: 700px) {
          display: block;
         }
`;

  
     
     
export const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  ${props=>props.disabled && `
  
  
  opacity:0.4;
  `}
  margin-right: 2rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => (!props.transparent ? "#00C853" : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:0;
${props=>(props.disabled && !props.transparent) && `
  
  
  background:gray;
  `}
 
`;


export const SliderStyle = styled.div`


 width: 75%;

    @media (max-width: 700px) {
          width: 100%;
          margin: 2rem auto;
         }
.pure-material-slider {
  display: inline-block;
 width: 100%;
  margin-top: 1.2rem;
  color: rgba(0, 0, 0, 0.87);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  line-height: 20px;
}

.pure-material-slider > input {
  display: block;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  height: 36px;
  background-color: transparent;
  cursor: pointer;
}

.pure-material-slider > input:focus {
  outline: none;
}

.pure-material-slider > input:disabled {
  cursor: default;
}

/* Webkit */
.pure-material-slider > input::-webkit-slider-runnable-track {
  margin: 17px 0;
  border-radius: 1px;
  width: 100%;
  height: 2px;
  background-color: gray;
}

.pure-material-slider > input:disabled::-webkit-slider-runnable-track {
  background-color: gray;
}

.pure-material-slider > input::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 50%;
  height: 2px;
  width: 2px;
  background-color: #00C853;
  transform: scale(6, 6);
  transition: box-shadow 0.2s;
}

.pure-material-slider > input:hover::-webkit-slider-thumb,
.pure-material-slider > input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px  rgba(0,0,0,0.3);
}

.pure-material-slider > input:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 2px   rgba(0,0,0,0.3);
}

.pure-material-slider > input:disabled::-webkit-slider-thumb {
  background-color: #00C853;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.3);
  transform: scale(4, 4);
}

/* Moz */
.pure-material-slider > input::-moz-range-track {
  margin: 17px 0;
  border-radius: 1px;
  width: 100%;
  height: 2px;
  background-color: #00C853;
}

.pure-material-slider > input:disabled::-moz-range-track {
  background-color: #00C853;
}

.pure-material-slider > input::-moz-range-progress {
  border-radius: 1px;
  height: 2px;
  background-color: #00C853;
}

.pure-material-slider > input:disabled::-moz-range-progress {
  background-color: #00C853;
}

.pure-material-slider > input::-moz-range-thumb {
  appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 50%;
  height: 2px;
  width: 2px;
  background-color: #00C853;
  transform: scale(6, 6);
  transition: box-shadow 0.2s;
}

.pure-material-slider > input:hover::-moz-range-thumb,
.pure-material-slider > input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 2px #00C853;
}

.pure-material-slider > input:active::-moz-range-thumb {
  box-shadow: 0 0 0 2px #00C853;
}

.pure-material-slider > input:disabled::-moz-range-thumb {
  background-color: #00C853;
  box-shadow: 0 0 0 1px #00C853;
  transform: scale(4, 4);
}

.pure-material-slider > input::-moz-focus-outer {
  border: none;
}

/* MS */
.pure-material-slider > input::-ms-track {
  box-sizing: border-box;
  margin: 17px 0;
  border: none;
  border: 1px solid #bdc3c7;
  border-radius: 1px;
  padding: 0 17px;
  width: 100%;
  height: 2px;
  background-color: transparent;
}

.pure-material-slider > input::-ms-fill-lower {
  border-radius: 1px;
  height: 2px;
  background-color: #00C853;
}

.pure-material-slider > input:disabled::-ms-fill-lower {
  background-color: #00C853;
}

.pure-material-slider > input::-ms-fill-upper {
  border-radius: 1px;
  height: 2px;
  background-color: #00C853;
}

.pure-material-slider > input:disabled::-ms-fill-upper {
  background-color: #00C853;
}

.pure-material-slider > input::-ms-thumb {
  appearance: none;
  margin: 0 17px;
  border: none;
  border-radius: 50%;
  height: 2px;
  width: 2px;
  background-color: #00C853;
  transform: scale(6, 6);
  transition: box-shadow 0.2s;
}

.pure-material-slider > input:hover::-ms-thumb,
.pure-material-slider > input:focus::-ms-thumb {
  box-shadow: 0 0 0 2px #00C853;
}

.pure-material-slider > input:active::-ms-thumb {
  box-shadow: 0 0 0 2px #00C853;
}

.pure-material-slider > input:disabled::-ms-thumb {
  background-color: #00C853;
  box-shadow: 0 0 0 1px #00C853;
  transform: scale(4, 4);
}


.pure-material-slider > .leftTag {
 position: absolute;
 top: 0; 
 left:0;
}

.pure-material-slider > .rightTag {
 position: absolute;
 top: 0; 
 left:0;
}









`;