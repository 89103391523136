import React, { Component } from 'react';
import styled , { 
  keyframes, 
  css, 
  ThemeProvider } from 'styled-components';

import { 
  Redirect, 
  Link,
  BrowserRouter, 
  Route, 
  Switch, 
  withRouter} from 'react-router-dom';

const _ = require('lodash');
//Code
const CARD_HEIGHT= "430px"
//Icons
const IconRoundClose = styled.div`
    color: red;
    position: absolute;
    top: 1rem; 
    right:1rem;
    font-size: 1.5rem;
    color: #000000;
    opacity: 0.54;
`;

const Container = styled.div`
    width: 50%;
    position: relative;
    margin: 4rem auto;
    padding-top: 100px;
    text-align: left;
    @media (max-width: 700px) {
    width: 90%;
    margin: 2rem auto;
    }  
 

`;

const TextContainer = styled.div`
    width: 60%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding-top: 4rem;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 40px;
  width: 100%;
  color:#202124;
  text-align: center;
  line-height: 1;
  @media (max-width: 700px) {
    font-size: 45px;
  }
`;

const Subtitle = styled.h2`
   width: 100%;
    font-weight: 400;
    font-size: 17px;
    color: #5F6469;
    text-align: left;
`;

const ImgContainer = styled.div`
    width: 40%;
    margin: 0 auto;
    text-align:center;
    img{
    width:100%;
     margin: 0 auto;
    }
`;

const ImgCircle = styled.div`
  display: none;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  img{
    width: 145%;
  }
  @media (max-width: 700px) 
  {
   display: inline-block;
  }
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin: 0 0.5rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : props.theme.colors.green)};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border: ${props => (!props.transparent ? "0" : `solid 1px ${props.theme.colors.green}`)};;
`;

const Label = styled.label`
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  a{
      color: #444444;
  }
  margin: 0 auto;
  margin-top: 2rem;
`;

const Body = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  background: #FAFAFA;
  margin-top: 100px;
  @media (max-width: 700px) {
    padding-top: 10px; 
  }
`;

const RoudLabel = styled.div`
  display: inline-block;
  border-radius: 22px;
  background: white;
  color: ${props=>props.theme.colors.green};
  border: 1px solid ${props=>props.theme.colors.green};
  padding: 0.2rem 1rem;
  font-size: 19px;
  margin: 2rem 0;
`;

const Card = styled.div`
    width: 100%;
   
   
    border-radius: 3px;
   border-bottom: 1px solid rgba(0,0,0,0.2);
    text-align: left;
    padding: 2rem 0;

`;

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
`;
const CardTitle = styled.h1`
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    color: #3C4043;
    text-align: left;
    margin: 2rem 0;
`;
const CardSubtitle = styled.h2`
    width: 100%;
    font-weight: 400;
    font-size: 17px;
    color: #5F6469;
    text-align: left;
`;

const More = styled.h2`
    width: 100%;
    font-weight: 500;
    font-size: 17px;
    color: #5F6469;
    margin: 150px 0;
    text-align: center;
    a{
      text-decoration: none;
      color: ${props=>props.theme.colors.green}
    }
`;

class App extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  authWithGoogle() { 
  }
  componentWillMount() { 
  }
  render() {

    return (
      <React.Fragment>
  
        <Container>
    
          <Title>
            Preguntas frecuentes
          </Title> 
          <br/>
          <br/>
          <br/>

          <Card>
            <CardTitle>
              ¿Cuáles son los requisitos?
            </CardTitle>
            <CardSubtitle>
              Solicitamos tu identificación oficial, comprobante de ingresos y cuenta bancaria.
            </CardSubtitle>

          
          </Card> 

          <Card>
            <CardTitle>
              ¿Mis datos están seguros?
            </CardTitle>
            <CardSubtitle>
              Por supuesto, contamos con mecanismos de seguridad y
              acciones para que tu información se resguarde de manera confidencial.
            </CardSubtitle>

          
          </Card> 


          <Card>
            <CardTitle>
              ¿Cuándo tengo que pagar?
            </CardTitle>
            <CardSubtitle>
              Dependiendo del día en que solicites tu adelanto, el cobro se realizará el siguiente día 15 o el último día de mes.
            </CardSubtitle>

          
          </Card> 


     
          <More>
            ¿Tienes más preguntas?<a > envía un mensaje a dudas@adelantat.com</a>
          </More>
        
        </Container>
       
      </React.Fragment>
    );
  }
}

export default App;