import React from 'react';
import styled from 'styled-components';
import { withRouter} from 'react-router-dom';

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.error(e)
  }
};
const Payment = withRouter((props)=> {

  const {
    folio = "74982",
    fecha = "Hace unos momentos",
    banco = "",
    clabe = "",
    convenio = "1202",
    cuenta = "",
    name = "Usuario",
    apellido = "Registrado",
    telefono = "+000000000000",
 
    //This is just metadata
    files_adress = "",
    files_id = "",
    files_nomina = "",
    //This is just metadata
    monto = "",
 
    empresa ="",
    telEmpresa ="",
    referencia="",
    telReferencia="",
  } = props.solicitud 


  return ( 
    <Button>
      <Content>
        <Item style={{width: "33%"}}> 
          <Title>Datos de solicitud</Title>
        </Item>

        <Item style={{width: "64%"}}> 
          <Subtitle>Folio: {folio}</Subtitle>
          <Subtitle>Fecha: { 
            (()=>{
              const d = new Date(fecha)
              return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
            })()
          }</Subtitle>
          <Subtitle>Cantidad solicitada: ${formatMoney(monto)}</Subtitle>
     
          {convenio && <Subtitle>Convenio: {convenio}</Subtitle>  }
        </Item>
      </Content>
      <Content>

        <Item style={{width: "33%"}}> 
          <Title>Datos del solicitante</Title>
        </Item>

        <Item style={{width: "64%"}}> 
          <Subtitle>Nombre: {name}  {apellido}</Subtitle>
          <Subtitle>Cel:{telefono}</Subtitle>
        </Item>

      </Content>
      <Content>

        <Item style={{width: "33%"}}> 
          <Title>Datos bancarios</Title>
        </Item>

        <Item style={{width: "64%"}}> 
          <Subtitle>Banco: {banco}</Subtitle>
          <Subtitle>N° de cuenta: {cuenta}</Subtitle>
          <Subtitle>CLABE:  {clabe}</Subtitle>
        </Item>

      </Content>

      <Content>


        <Item style={{width: "33%"}}> 
          <Title>Compañia</Title>

        </Item>

        <Item style={{width: "64%"}}> 


          <Subtitle>Empresa: {empresa}</Subtitle>
          <Subtitle>Telefono empresa: {telEmpresa}</Subtitle>
   
      
        </Item>

      </Content>


      <Content>


        <Item style={{width: "33%"}}> 
          <Title>Referencia</Title>

        </Item>

        <Item style={{width: "64%"}}> 


          <Subtitle>Nombre referencia: {referencia}</Subtitle>
          <Subtitle>Telefono referencia: {telReferencia}</Subtitle>
   
      
        </Item>

      </Content>



      <Content>


        <Item style={{width: "33%"}}> 
          <Title>Archivos Compartidos

          </Title>

        </Item>

        <Item style={{width: "64%"}}> 

          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_id,
                  "👤 Identificación"
                )
              }}>Identificación  </a></Subtitle>
          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_adress,
                  "✉️ Comprobante de domicilio"
                )
              }}

            >Comprobante de domicilio </a></Subtitle>

          {props.solicitud.files_nomina &&     
          <Subtitle>
            <a
              href="#"
              onClick={(e)=>{
                e.preventDefault();
                props.setCurrentFile(
                  files_nomina,
                  "💳 Comprobante de Nómina"
                )
              }}
 
            > Comprobante de Nomina </a></Subtitle>}
 
        </Item>

      </Content>






    </Button>

     
  );             
}
) 
export default Payment



const Button = styled.div`

width: 100%;
margin: 0 auto;

border-radius: 5px;
position: relative;
margin: 1rem auto;
background: white;
color: #212121;


`; 

const Content = styled.div`
  ${props=>props.theme.default.utils.rowContent}
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
  border-bottom: solid 1px rgba(0,0,0,0.2);
     @media (max-width: 700px) {
          display: block;
         }
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
       @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;



const Item = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}


         @media (max-width: 700px) {
          margin-top: 1rem; 
         }
`;



const Title = styled.div`
width: 100%;

display: block;
font-size: 19px;
font-weight: 600;
color: #313033;
text-align: left;

`;

const Subtitle = styled.div`
width: 100%;
display: block;
font-size: 16px;
color: #5F6468;
font-weight: 300;

`;