import React, { Component } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import {  Redirect, 
  Link,
  BrowserRouter, 
  Route, 
  Switch, 
  withRouter
} from 'react-router-dom';

//import FileButton from "./Components/Molecules/FileButton"
import Navbar from "./Components/Molecules/Navbar";
import firebase from "firebase";
import Rebase from "re-base";
import Loading from "Components/Molecules/Loading";
import SuccessCard from "Components/Molecules/SuccessCard";
import {Form, SliderStyle,Monto, Row} from "./Files/styles";
import {Item} from "./Components/Molecules/Item";
import {Input, Select} from "./Components/Molecules/Inputs";
import Uploader from "./Components/Molecules/UploadFileButton"
import {createSolicitud} from "Files/General/DatabaseQueries/Post/Solicitudes"
import {getUsuario} from "Files/General/DatabaseQueries/Get/Usuarios"
import {uploadFiles} from "Files/General/DatabaseQueries/Post/Files"
import ConfirmationCode from "Components/Molecules/ConfirmationCode"
import BANKS from "./BanksArray"

const _ = require('lodash');
const uuid = require('uuid');

const Icon = styled.i`
        vertical-align: bottom;
        margin-right: 0.1rem;
      `;

class App extends React.Component 
{
  constructor()
  {
    super()
    this.state = {
      confirm: false,
      success: false,
      //Put monto inside of DATA
      monto: 2000,
      buro: false,
      data:{
        clabe:"",
        cuenta:"",
        banco:"none",
        files_nomina:"",
        files_id:"",
        files_adress:"",
        empresa:"",
        telEmpresa:"",
        referencia:"",
        telReferencia:""
      },
      file:{
        domicilio: null,
        nomina: null,
        id: null
      },
      profile: {},
      loading:  true,
      uploading:  false,
      ready: false,
      archivosCargados: 0,
      slider: 0,
      newComponent: null,
      continue: 0,
    }
    
    this.component = null;
    this.base = Rebase.createClass(firebase.database());
  }

  nextSlide()
  {
    if(this.state.slider <= 4)
      this.setState({slider: this.state.slider + 1})
  }

  backSlide()
  {
    if(this.state.slider > 0)
      this.setState({slider: this.state.slider - 1})
  }

  async  componentDidMount()
  {
    const user = await getUsuario(
      firebase.auth().currentUser.uid,
      (data)=>{ 
        const  {
          convenio="",
          clabe="",
          cuenta="",
          banco="none",
          files_nomina="",
          files_id="",
          files_adress ="",
          empresa ="",
          telEmpresa ="",
          referencia="",
          telReferencia="",
        } =  data;

        const solicitud = {...this.state.data,
          convenio,
          clabe,
          cuenta,
          banco,
          files_nomina,
          files_id,
          files_adress,
          empresa,
          telEmpresa,
          referencia,
          telReferencia,
        }      
        this.setState({profile: data, data: solicitud, file:{
          ...this.state.file,  files_nomina,
          files_id,
          files_adress
        }, loading: false})
      }
    )
  }

  componentDidUpdate()
  { 
    const formValidation = this.validateForm();
    if(formValidation !== this.state.ready)
    {
      this.setState({ready: formValidation})
    }

    const canContinue = this.canContinue(); 
    if(canContinue !== this.state.continue)
    {
      this.setState({continue: canContinue})
    }
  }

  canContinue()
  {

    if(this.state.slider === 0)
      return true
    else if(this.state.slider === 2 )
    {
      if (
        this.state.data.empresa.length >= 5
            && this.state.data.telEmpresa.length === 10
            && this.state.data.referencia.length >= 5 
            && this.state.data.telReferencia.length === 10
      ) 
        return true
    }
    else if(this.state.slider === 1)
      if (this.state.buro === true
                && this.state.data.banco !== "none" 
                && this.state.data.clabe.length == 18
                && (this.state.data.cuenta.length <= 18 && this.state.data.cuenta.length >= 4)
      ) 
        return true
    return false     
  }
  
  validateForm()
  {
    if(     
    //this.state.data.convenio
      this.state.buro
          &&
          this.state.monto
          && this.state.data.clabe.length === 18
          && this.state.data.cuenta.length <= 18
          && this.state.data.cuenta.length >= 4
          && this.state.data.banco
          && this.state.data.banco !== "none"
          && this.state.data.empresa.length >= 5
          && this.state.data.telEmpresa.length === 10
          && this.state.data.referencia.length >= 5
          && this.state.data.telReferencia.length === 10
          && this.state.data.files_nomina
          && this.state.data.files_id
          && this.state.data.files_adress
    )return true
    return false
  
  }

  async crearNuevaSolicitud(code)
  {
    //this.state.profile.telefono
    const id = uuid();
      
    this.setState({uploading: true, confirm: false});
      
    await createSolicitud({
      ...this.state.profile, 
      ...this.state.data,  
      allfiles:this.state.files, 
      monto: this.state.monto, 
      buro: this.state.buro 
    },(data)=>{

      firebase.database().ref(`bitacora/${firebase.auth().currentUser.uid}/solicitudes/${data.id}`).set({ 
        id: data.id,
        fechaAutorizo: Date.now(),
        codigoAutorizo: code
      })
        .catch((err)=>{
          this.setState({loading: false})
          alert("Alerta, hubo un error.")       
        })

      this.setState({newComponent: data.id})

    });


    //Fetch counter
    const files = [];

    if(this.state.file.nomina)
      files.push(this.state.file.nomina)

    if(this.state.file.domicilio !== null)
      files.push(this.state.file.domicilio)

    if(this.state.file.id !== null)
      files.push(this.state.file.id)

    await uploadFiles(files, ()=>{this.setState({
      archivosCargados: this.state.archivosCargados + 1})
    });

    this.setState({success: true, confirm: false, uploading: false})
  }
  
  render()
  {     
    return ( 
      <React.Fragment>
        <Form>
          {this.state.slider === 0 &&      
          <Item 
            title="Selecciona una cantidad" 
            subtitle="Indica la cantidad a solicitar. Por favor selecciona la
cantidad que deseas como adelanto, recuerda que el monto máximo es hasta el 50% de tu
siguiente pago de nómina.">
            <Row>
              <SliderStyle>
                <div className="pure-material-slider">

                  <input
                    value={this.state.monto} 
                    onChange={(e)=>{this.setState({monto:e.target.value})}}
                    type="range" 
                    step={500} 
                    min="500" 
                    max="5000"/>
                </div>
              </SliderStyle>
              <Monto> $ {this.state.monto}</Monto>
            </Row>
            <br/>
            <br/>
          </Item>
          }

          {this.state.slider === 1 && 
          <Item 
            title="Ingresa tus datos bancarios" 
            subtitle=""
          >
                
            <Select
              placeholder="Banco"  
              value={this.state.data.banco}
              options={BANKS}
              onChange={(banco)=>{
                this.setState({data:{...this.state.data, banco }})
              }}    
            />
  
            <Input
              error={(()=>{
                if(this.state.data.cuenta && !(this.state.data.cuenta.length >= 4 && this.state.data.cuenta.length <= 18))
                  return "El número de cuenta no pude ser mayor a 18 dígitos ni menor que 4."
                return null
              })()} 
              placeholder="N° de Cuenta"
              maxlength="18"
              value={this.state.data.cuenta}
              onChange={(cuenta)=>{this.setState({data:{...this.state.data, cuenta }  })} }
            />
        
            <Input
              error={(()=>{
                if(this.state.data.clabe && this.state.data.clabe.length !== 18)
                  return "La CLABE es de 18 caracteres."
                return null
              })()} 
              placeholder="CLABE (18 digitos)"
              value={this.state.data.clabe}
              maxlength="18"
              onChange={(clabe)=>{

                this.setState({data:{...this.state.data, clabe }  })} }
            />
        
            <div
              onClick={()=>{
                this.setState({buro: !this.state.buro})}}> 
              <Icon 
                className="material-icons" 

                style={{"verticalAlgin":"bottom", 
                  fontSize:"22px",
                  cursor:"pointer"}}>
                {this.state.buro?"check_box":"check_box_outline_blank"}
              </Icon> Aceptas que verifiquemos tu buro de crédito </div>
          </Item>}

          {this.state.slider === 2 &&   <Item 
            moveTop = {true}

            title="Ingresa tus referencias"
            subtitle="">
           
            <h3>Compañia</h3>
            <Input 
              
              error={(()=>{
                if(this.state.data.empresa && this.state.data.empresa.length < 5  )
                  return "Introducir minimo 5 caracteres"
                return null
              })()} 
              placeholder="Empresa en la que trabajas"
              value={this.state.data.empresa}
              onChange={(empresa)=>{
                this.setState({data:{...this.state.data,empresa}})    
              }}
            /> 
            <Input 
              maxlength="10"
              error={(()=>{
                if(this.state.data.telEmpresa && this.state.data.telEmpresa.length < 10)
                  return "Introducir 10 dígitos"
                return null
              })()} 
              placeholder="Teléfono de la empresa (10 dígitos)"
              value={this.state.data.telEmpresa}
              onChange={(telEmpresa)=>{
                this.setState({data:{...this.state.data,telEmpresa}})    
              }}
            /> 
            <h3>Persona de confianza</h3>
            <Input 
              
              error={(()=>{
                if(this.state.data.referencia && this.state.data.referencia.length < 5)
                  return "Introducir minimo 5 caracteres"
                return null
              })()} 
              placeholder="Nombre de una referencia personal"
              value={this.state.data.referencia}
              onChange={(referencia)=>{
                this.setState({data:{...this.state.data,referencia}})    
              }}
            /> 

            <Input 
              maxlength="10"
              error={(()=>{
                if(this.state.data.telReferencia && this.state.data.telReferencia.length < 10)
                  return "Introducir 10 dígitos"
                return null
              })()} 
              placeholder="Teléfono de referencia personal (10 dígitos)"
              value={this.state.data.telReferencia}
              onChange={(telReferencia)=>{
                this.setState({data:{...this.state.data,telReferencia}})    
              }}
            /> 
          </Item>}

          {this.state.slider === 3 && 
          <Item 
            title="Anexar documentos"
            subtitle="">

            <Uploader 
              file= {this.state.data.files_id}

              title="Identificación (imagen)"
              onChange={(files_id, file)=>{this.setState({
                data:{...this.state.data, files_id },
                file:{...this.state.file, id:file } 
              })}
        
              }/>  
        
            <Uploader
              file= {this.state.data.files_adress}
              title="Comprobante de domicilio (imagen)"
              onChange={(files_adress, file)=>{
                this.setState({
                  data:{...this.state.data, files_adress },
                  file:{...this.state.file, domicilio:file } 
                })}
              }/> 
        
            <Uploader 
              file= {this.state.data.files_nomina}
              title="Comprobante de nómina (Imagen o XML)."
              onChange={(files_nomina, file)=>{
                this.setState({
                  data:{...this.state.data, files_nomina },  
                  file:{...this.state.file, nomina:file } 
                })}}
            /> 
           
          </Item>}

        </Form> 

        <Navbar
          current = {this.state.slider}
          nextSlide = {this.nextSlide.bind(this)}   
          backSlide = {this.backSlide.bind(this)}
          canContinue = {!this.state.continue }
          continue={()=>{this.setState({confirm: true})}}
          canSubmit={this.state.ready}
        />
 
        {this.state.confirm &&   <ConfirmationCode 
          
          close={()=>{this.setState({confirm: false})}}
          phone = {this.state.profile.telefono}
          continue={(code)=>{this.crearNuevaSolicitud(code)}}  />
        }

        {this.state.success &&  
          <SuccessCard 
            id={this.state.newComponent}
            data={{ 
              ...this.state.profile,
              ...this.state.data, 
              monto:this.state.monto
            }}

          />
        }

        {this.state.loading &&
          <Loading text="Cargando datos del usuario"/>
        }

        {this.state.uploading &&
        <Loading text="Se estan enviado sus archivos, espere un momento..."/>
        }

      </React.Fragment>
    );
  }
}

export default App