import Rebase from "re-base";
import firebase from "firebase";


const base = Rebase.createClass(firebase.database());
const uuid = require('uuid');

export async function createDispersion(data){
  //Actualizamos toda la informacion del usuario.
  //[Solicitud]
 
  const {
    id  = "",
    banco =  "",
    clabe = "",
    convenio = "",
    cuenta = "",
    usuario = "",
    nombreUsuario = "",
    folio = "",
    empresa = "",

    telEmpresa,
    referencia,
    telReferencia,
  } =  data

  firebase.database().ref(`dispersiones/${id}`).set(
    {
      id,
      folio,
      banco,
      clabe,
      empresa,

      telEmpresa,
      referencia,
      telReferencia,
      cuenta,
      usuario,
      fecha: Date.now(),
      nombreUsuario,
      solicitud: data.id,
      monto: data.montoAprobado,
      status: 1,
      action: "NEW_DISPERSION"
    }).then((res)=>{

  }).catch((err)=>{
    alert("Hubo un error con la conexion, intenta de nuevo.")
    //Show Error mesage
  })
}