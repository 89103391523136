import Rebase from "re-base";
import firebase from "firebase";

const base = Rebase.createClass(firebase.database());

export async function getUsuario(userId, callback = null){
  const data = await base.fetch(`profile/${userId}`, {
    then(data){

      if(callback){
        callback(data)
      }
    }
  }).catch((error)=>{
    alert("500 Algo salio mal con tu conexion, inente mas tarde...")
    console.error(error)
    
  })
  return data
}