import React, { Component } from 'react';
import styled from 'styled-components';



import Loading from "Components/Molecules/Loading";



import Close from "react-md-icon/dist/BaselineClose";

import {getFile} from "Files/General/DatabaseQueries/Get/Files"


class FileModal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      url: "",
      loading: true
    }
  }

  async componentDidMount(){
  		getFile(this.props.file, (url)=>{
  				this.setState({url, loading: false})
  		})    
  }

  isImg(name = ""){

  	return name.includes(".jpg") || name.includes(".png") || name.includes(".jpge") || name.includes(".gif")

  }

  render()
  {

    const close = this.props.close

  
    if(this.state.loading){
      return(
        <Loading text="Abriendo Archivo." />
      )
    }

    if(!this.state.loading && !this.state.url)
    {
    	alert("Falló la descarga del archivo, crea una nueva solicituad para actualizar tus archivos")
    	this.props.close()
    }

    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          <NavBar>
            <Content>
              <AlignStart>
                <Item2>
      
                  <Title2>
                    {this.props.title}
                  </Title2>
 
                </Item2>    
              </AlignStart>
              <AlignEnd>
              </AlignEnd>
            </Content>
            <Close
              onClick={close} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
                zIndex:"99999999"

              }}

            />
          </NavBar>
          {this.props.file && <Container>
            {! this.isImg(this.props.file) ?
              (
                <iframe 
                  title="file"
                  src={this.state.url}
                />
              ):
              (<img
                src={this.state.url}
                alt="file" />) }
          </Container>}
        </Card>
      </FullScreen>
    );
  }
}
      
export default FileModal

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top:1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  padding: 1rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
  text-align:center;
 img{
 	width:90%;
 	margin: 0 auto;
 }
 iframe{
  width:100%;
  height:400px;
 }
`;


const NavBar = styled.div`
 position: absolute;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;
  //box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;

const Subtitle = styled.h4`
font-weight: 400;
text-align: center;
color: #5F6469;
font-size: 16px;
margin: 0.5rem 0;  

`;

const NavTextContainer = styled.div`
  width: 100%;
  text-align: left;

`;

const IconContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const Icon = styled.i`
  vertical-align: bottom;
  cursor:pointer;
`;

const Img = styled.img`
width: 150px;
margin: 1rem auto;
text-align: center;

`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;


const ContainerBox = styled.div`
  width:100%;
  border: 1px gray solid;
  border-radius: 7px;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 2.3rem;
  text-align: center;

`;

const Button = styled.button`

  position: relative;
  padding: 0.6rem 1rem;
  
  
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => props.theme.colors.green};
  color: white;
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border:0;

 
`;





const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;



const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}

  
   @media (max-width: 700px) {
          width: 100%;
         }
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
               
           }
          
          
        `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}

`;



const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;

const Subtitle2 = styled.div`
font-weight: 500;
text-align: left;
color: #5F6469;
font-size: 16px;
margin: 0 0.5rem;


`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;

