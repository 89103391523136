import React, { Component } from 'react';
import styled  from 'styled-components';
import {Link} from 'react-router-dom';
import mainImg from "static/img/a.png"

class Top extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  
  authWithGoogle() {
    
  }

  componentWillMount() {
    
  }
  render() {

    return (
      <React.Fragment>

   
        <Container>
          <Row>
            <TextContainer>

              <Title>
                Adelanta tu <br/> salario, fácil, rápido y seguro.
              </Title>
              <Subtitle>
                Un servicio simple para obtener un adelanto de tu salario
                de forma sencilla y con total privacidad.
              </Subtitle>
              <Link to="/registro">
                <Button
                  shadow>
                  Solicitar adelanto
                </Button>
              </Link> 
              <a href="/#como">
                <Button
                  transparent
                  shadow>
                  Más información
                </Button>
              </a>
        
            </TextContainer>
        
            <ImgContainer >
              <img
                alt="top"
                src={mainImg} 
              />
            </ImgContainer>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}



export default Top;


const Container = styled.div`
    width: 70%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 700px) {
   width: 90%;
  }
`;

const TextContainer = styled.div`
    width: 60%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding-top: 5rem;
     @media (max-width: 700px) {
   width: 100%;
   padding: 2rem 0;

  }

`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 70px;
  width: 92%;
  color:${(props)=>props.theme.colors.green};
  text-align: left;
  line-height: 1;
  @media (max-width: 700px) {
    font-size: 45px;
  }

`;

const Subtitle = styled.h2`
   width: 100%;
    font-weight: 500;
    font-size: 22px;
    color: #5F6469;
    text-align: left;
`;

const ImgContainer = styled.div`
    width: 40%;
    margin: 0 auto;
    text-align:center;
    img{
    width:100%;
     margin: 0 auto;
    }
     @media (max-width: 700px) {
  display: none;
  }
`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : props.theme.colors.green)};
  cursor: pointer;
  font-weight: normal;
    font-size: 18px;
  border: ${props => (!props.transparent ? "0" : `solid 1px ${props.theme.colors.green}`)};;
`;


 

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}

   @media (max-width: 700px) {
    display: block;
  }

`;


