
import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";

		 //Imports.
//{getImports(data.component.imports)}
   

import Document from "./Components/Molecules/Document"
import Navbar from "./Components/Molecules/Navbar"

const _ = require('lodash');

const DetallesSolicitud = withRouter((props)=> {


  //Code
                        
  const Body = styled.div`
		width: 90%;
		margin: 0 auto;
`;





  return(
    <Body>
      <Navbar />
      <Document id={props.match.params.id}/>

    </Body>)
                
}
)

        


export default DetallesSolicitud