import firebase from "firebase";

export async function getFile(filename, callback = null)
{
  const URL = `users_files/${firebase.auth().currentUser.uid}/${filename}`
  var storage = firebase.storage();
  var storageRef = storage.ref(URL);
  storageRef.getDownloadURL().then((url) => {
    if(callback)
      callback(url)
  }).catch((error) => {
    callback(null);
    console.error(error)
  });
}

/*
// `url` is the download URL for 'images/stars.jpg'
          ("URL",url)
    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
    var blob = xhr.response;
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element:
    var img = document.getElementById('myimg');
    img.src = url;  

    */


