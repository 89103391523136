import React, { Component } from 'react';
import styled , { 
  keyframes, 
  css, 
  ThemeProvider } from 'styled-components';

import { 
  Redirect, 
  Link,
  BrowserRouter, 
  Route, 
  Switch, 
  withRouter} from 'react-router-dom';

const _ = require('lodash');
//Code
const CARD_HEIGHT= "430px"
//Icons
const IconRoundClose = styled.div`
    color: red;
    position: absolute;
    top: 1rem; 
    right:1rem;
    font-size: 1.5rem;
    color: #000000;
    opacity: 0.54;
`;

const Container = styled.div`
        width: 70%;
    position: relative;
    margin: 3rem auto;
    text-align: left;
     @media (max-width: 700px) {
    width: 90%;
  }
`;

const TextContainer = styled.div`
    width: 60%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding-top: 5rem;
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  width: 100%;
  color:#202124;
  text-align: left;
  line-height: 1;
  @media (max-width: 700px) {
    font-size: 45px;
  }

`;

const Subtitle = styled.h2`
   width: 100%;
    font-weight: 500;
    font-size: 17px;
    color: #5F6469;
    opacity: 0.77;
    text-align: left;
`;

const ImgContainer = styled.div`
    width: 40%;
    margin: 0 auto;
    text-align:center;
    img{
    width:120%;
     margin: 0 auto;
    }
`;

const ImgCircle = styled.div`
  display: none;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  img{
    width: 145%;
  }
  @media (max-width: 700px) 
  {
   display: inline-block;
  }
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  text-align: left;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 16px;
  border:0;
`;
const Label = styled.label`
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  a{
      color: #444444;
  }
  margin: 0 auto;
  margin-top: 2rem;
`;

 

const Card = styled.div`
  width: 29%;
  height: 250px;
  background: white;
  border-radius: 3px;
  box-shadow:   0 0.3px 5px rgba(0,0,0,0.2);
  text-align: center;
  padding: 1rem;
   ${props=>props.theme.default.utils.centerContent}
    @media (max-width: 700px) {
  width: 100%;
  margin: 1rem 0;
  }
  
`;

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
    @media (max-width: 700px) {
    display: block;
  }

`;


class App extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  
  authWithGoogle() {
    
  }

  componentWillMount() {
    
  }
  render() {

    return (
      <React.Fragment>
   
        <Container>
          <Row>
            <Card >

              <Title>
                Solicitud ágil.
              </Title>
              <Subtitle>
                Es un proceso sencillo y rápido porque entendemos tus
                necesidades.
              </Subtitle>
        
            </Card>


            <Card >

              <Title>
                Transacciones seguras.


              </Title>
              <Subtitle>
                Recibes una transferencia directamente a tu cuenta de
                banco.
              </Subtitle>
        
            </Card>

            <Card >

              <Title>
                Sin letras pequeñas.
              </Title>
              <Subtitle>
                Sabes lo que vas a pagar desde el inicio, sin cargos
                adicionales.
              </Subtitle>
        
            </Card>
        
        
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}



export default App;