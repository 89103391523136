import React, { Component } from 'react';
import styled from 'styled-components';

const _ = require('lodash');
 
export const SelectStyles = styled.select`
    padding: 0 1rem;
    
    font-size: 18px;
    margin: 0.5rem auto;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    position: relative;
    background: white;
    color: black;
    border: 1px solid #E5E5E5;
     &:focus {
    outline: none !important;
    border:1.5px solid ${props=>props.theme.colors.green};
    
    }
  
`;


const InputStyles = styled.input`
    padding:  1rem;
    font-size: 18px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    position: relative;
    background: white;
    color: #212121;
    border: 1px solid #E5E5E5;  
    &:focus {
    outline: none !important;
    ${props=>!props.error?`border:1.5px solid ${props.theme.colors.green};`:
    `border:1.5px solid red;

    `
}
    
    }

    ${props=>props.error && `
        border:1.5px solid red;
        `}
    margin: 0.5rem auto;
    }

     ${props=>props.disabled && `
        background: rgba(0,0,0,0.2);
        `}
    
    }
`;
const Body = styled.div`
  
    position: relative;
  width: 100%;
   margin: 0 auto;
`;
const TagError = styled.label`
    position: relative;
    color:red;
    background: white;
    padding:0 0.1rem;
    border-radius:2px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 1rem;
    
  
`;

const Tag = styled.label`
    position: absolute;
    color:${props=>props.theme.colors.green};
    background: white;
    padding:0 0.1rem;
    border-radius:2px;
    font-size: 12px;
    font-weight: 500;
    top: -1px;
    left: 0.7rem;
     ${props=>props.error && `
        color: red;
        `}
`;
export class Input extends Component{
  constructor(){
    super();
    this.state = {
      focus: false,
      unselected: false,
    }
  }

  render(){
       
    return (
      <Body>
        <InputStyles 
          disabled = {this.props.disabled}
          error = {this.props.error  && !this.state.focus}
          onBlur = {()=>{this.setState({focus: false, unselected:true})}}
          onFocus = {()=>{this.setState({focus: true})}}
          type={this.props.type || "text" }
          maxlength={this.props.maxlength}
          onChange={(e)=>{this.props.onChange(e.target.value)}}
          value = {this.props.value} 
          placeholder = {this.props.placeholder}/>

        {(this.state.focus || this.props.value)  && <Tag
          error = {this.props.error  && !this.state.focus}>{this.props.placeholder}</Tag>} 
    
        {(this.props.error  && !this.state.focus) && <TagError>{this.props.error}</TagError>} 
    
      </Body>)   
  }
}
 
export const Select = (props)=>(
  <Body>
    <SelectStyles
      value={props.value}
      onChange={(e)=>{props.onChange(e.target.value)}}> 

      {props.options && props.options.map((item, id)=>(
        <option 
          key={id}
          value={item.value}>{item.title}</option>))
      }
    </SelectStyles> 
    {/*props.value && <Tag>{props.placeholder}</Tag>*/}
  </Body>
)