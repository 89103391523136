import styled from 'styled-components';

export const IconContainer = styled.div `
	width: 28px;
	height: 28px;
	margin-right: 19px;
`

export const Text = styled.div `
	font-family: Rubik, 'sans-serif';
	height: 40px;
	width: 100%;
	cursor: pointer;
	padding-left: 24px;
	background: white;
	display: flex;
	align-items: center;
	font-size: 14px;

	&:hover {
		background: #F0F0F0;
	}

	a {
		text-decoration: none;
		color: #727272;
		display: flex;
		align-items: center;
	}

	a:hover {
		color: ${props => props.theme.color.blackCode};
	}
`
