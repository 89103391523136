import styled from 'styled-components';
import RoundAccountBox from "react-md-icon/dist/RoundAccountBox";
import RoundExplore from "react-md-icon/dist/RoundExplore";
import RoundSettings from "react-md-icon/dist/RoundSettings";
import RoundExitToApp from "react-md-icon/dist/RoundExitToApp";


export const Container = styled.div `
	width: 100%;
	height: 100%;
	background: transparent;
	position: relative;
	z-index: 9999999;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const PseudoContainer = styled.div `
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: transparent;
	z-index: 99;
`

export const Content = styled.div `
	width: 290px;
	height: auto;
	background: white;
	border-radius: 7px;
	box-shadow: 0 0.5px 6px rgba(0, 0 ,0, 0.4);
	position: absolute;
	right: 0;
	z-index: 9999999999999999;
	overflow: hidden;
	padding-bottom: 37px;

	${props => props.theme.media.tablet `
		position: fixed;
		width: 100%;
		bottom: 0;
	`}
`

export const ImageContainer = styled.div `
	width: 65px;
	height: 65px;
	margin-right: 8.55px;
	border-radius: 50%;
	background: #F5F5F5;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	${props => props.theme.media.tablet `
		width: 50px;
		height: 50px;
		margin-right: 12px;
	`}
`

export const Image = styled.img `
	width: 100%;
	height: 100%;
`

export const Name = styled.div `
	line-height: 0.8;
	font-size: 18px;
`

export const Email = styled.div `
	font-family: Rubik, 'sans-serif';
	font-size: 14px;
	color: #8D8D8D;
`

export const Information = styled.div `
`

export const Description = styled.div `
	width: 100%;
	height: 120px;
	

	text-aling: left;
`

export const AccountIcon = styled(RoundAccountBox) `
	font-size: 30px;
`

export const ExploreIcon = styled(RoundExplore) `
	font-size: 30px;
`

export const SettingsIcon = styled(RoundSettings) `
	font-size: 30px;
`

export const ExitIcon = styled(RoundExitToApp) `
	font-size: 30px;
`
