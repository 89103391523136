import React, { Component } from 'react';
import styled , { 
  keyframes, 
  css, 
  ThemeProvider } from 'styled-components';

import { 
  Redirect, 
  Link,
  BrowserRouter, 
  Route, 
  Switch, 
  withRouter} from 'react-router-dom';



const Container = styled.div`
    width: 70%;
    position: relative;
    margin: 0 auto;
    padding: 100px 0;
    text-align: left;
     @media (max-width: 700px) {
     width: 90%;
  }
    
`;

const TextContainer = styled.div`
    width: 50%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    padding-top: 4rem;
     @media (max-width: 700px) {
    width: 100%;
    margin: 2rem auto;
    }  
  
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 50px;
  
  color:#202124;
  text-align: left;
  line-height: 1;
 

`;

const Subtitle = styled.h2`
   width: 100%;
    font-weight: 400;
    font-size: 22px;
    color: #5F6469;
    text-align: left;
`;

const ImgContainer = styled.div`
    width: 40%;
    margin: 0 auto;
    text-align:center;
    img{
    width:100%;
     margin: 0 auto;
    }
       @media (max-width: 700px) {
     display: none;
  }
`;

const ImgHiden = styled.img`
  display: none;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  
  @media (max-width: 700px) 
  {
   display: inline-block;
  }
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin: 0 0.5rem;

  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : props.theme.colors.green)};
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  border: ${props => (!props.transparent ? "0" : `solid 1px ${props.theme.colors.green}`)};;
`;
const Label = styled.label`
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  a{
      color: #444444;
  }
  margin: 0 auto;
  margin-top: 2rem;
`;

const Body = styled.div`
  width: 100%;
  overflow: hidden;
 
  position: relative;
   background: ${props=> props.white?"white":"#FAFAFA"};
  


`;

const RoudLabel = styled.div`
  display: inline-block;
  border-radius: 22px;
  background: white;
  color: ${props=>props.theme.colors.green};
  border: 1px solid ${props=>props.theme.colors.green};
  padding: 0.2rem 1rem;
  font-size: 19px;
  margin: 2rem 0;
`;


const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
    @media (max-width: 700px) {
   display: block;
  }

`;


class App extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  
  authWithGoogle() {
    
  }

  componentWillMount() {
    
  }
  render() {

    return (
      <React.Fragment>
        <Body white={this.props.color} >
          <Container>
            <Row>

              {this.props.inverse?

                <React.Fragment>

                  <ImgContainer>
                    <img
                      src={this.props.img} 
                    />
                  </ImgContainer>
                  <TextContainer >
                    {this.props.label && <RoudLabel>
                      {this.props.label}
                    </RoudLabel>}

                    <ImgHiden src={this.props.img}/>
                    <Title>
                      {this.props.title}
                    </Title>
                    <Subtitle>
                      {this.props.subtitle}
                    </Subtitle>
                    {this.props.actions}
                  </TextContainer>
              
                </React.Fragment>


                :
                <React.Fragment>
                  <TextContainer >
                    {this.props.label && <RoudLabel>
                      {this.props.label}
                    </RoudLabel>}
                            
                    <ImgHiden src={this.props.img}/>
                    <Title>
                      {this.props.title}
                    </Title>
                    <Subtitle>
                      {this.props.subtitle}
                    </Subtitle>
                    {this.props.actions}
                  </TextContainer>
              
                  <ImgContainer>
                    <img
                      src={this.props.img} 
                    />
                  </ImgContainer>
                </React.Fragment>
              }


            </Row>
          </Container>
        </Body>
      </React.Fragment>
    );
  }
}



export default App;