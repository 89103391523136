import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, Route} from 'react-router-dom';
import Toolbar from "./Components/Molecules/Toolbar"
import RegisterForm from "./Components/Molecules/RegisterForm"
import Footer from "Components/Molecules/Footer"
import TopSection from "./Components/Sections/Top"
import CardsSection from "./Components/Sections/Cards"
import SlidesSection from "./Components/Sections/Slides"
import ComisionSection from "./Components/Sections/Comision"
import QandASection from "./Components/Sections/QandA"
import ByeSection from "./Components/Sections/Bye"
import perk1 from "static/img/b.png"
import perk2 from "static/img/c.png"
import perk3 from "static/img/d.png"
import perk4 from "static/img/e.png"
import perk5 from "static/img/a.png"
 
class Landing extends Component{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  

  render() {

    return (
      <React.Fragment>
        <Body>
      
          <Toolbar 
            landing={true}/>
      


          <div id={"inicio"} /> 
        

          <TopSection />

          <CardsSection />

          <div id={"como"} /> 
          <SlidesSection
            color={false}
            inverse={false}
            label={"Paso 1"} 
            img={perk1}
            title={"Regístrate en Adelanta-t"}
            subtitle={"Para solicitar tu adelanto es necesario que te registres ingresando tus datos personales: Nombre, correo electrónico y número de teléfono."}
            actions={
              <React.Fragment>
                <Link to="/registro">

                  <Button shadow>
                    Solicitar adelanto
                  </Button>

                  <Button
                    transparent
                    shadow>
                    Más información
                  </Button>

                </Link> 
              </React.Fragment>}
          />

          <SlidesSection
            color={true}
            inverse={true}
            label={"Paso 2"} 
            img={perk2}
          
            title={"Realiza y envía tu solicitud"}
            subtitle={"Indica la cantidad que deseas, anexa documentos y confirma tu solicitud."}
            actions={
          
              <React.Fragment>

                <Link to="/registro">

                  <Button
                    shadow>
                    Iniciar
                  </Button>
                  <Button
                    transparent
                    shadow>
                    Información necesaria
                  </Button>
                </Link> 
      
              </React.Fragment>}
          />

          <SlidesSection
            color={false}
            inverse={false}
            label={"Paso 3"} 
            img={perk3}
            title={"Confirma las condiciones de pago"}
            subtitle={"Acepta el monto y fecha de pago."}
            actions={
              <React.Fragment>
                    
                <Link to="/registro">
                  <Button
                    transparent
                    shadow>
                    Condiciones de validación
                  </Button>
                </Link> 
                  
              </React.Fragment>
            }

          />

          <SlidesSection
            color={true}
            inverse={true}
            img={perk4}
            label={"Paso 4"} 
            title={"Recibe tu dinero"}
            subtitle={"Directo a tu cuenta de banco."} 
          />
          <div id={"cobro"} /> 
     
          <ComisionSection />
          <div id={"ASK"} /> 
          <QandASection />

          <ByeSection
            img={perk5}
            title={"Adelanta tu salario, fácil, rápido y seguro."}
            actions={
        
              <React.Fragment>

                <Link to="/registro">
                  <Button
                    transparent
                    shadow>
                    Solicitar adelanto
                  </Button>
                </Link> 
    
              </React.Fragment>}
          />
          <Route
            path="/registro"
            render={()=> {
              return (<RegisterForm 
                continue={()=>{
                  this.setState({confirm:true})}} />) }
            }/>
          <Footer />
        </Body>

      </React.Fragment>
    );
  }
}

export default Landing;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin: 1rem 0.5rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : props.theme.colors.green)};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border: ${props => (!props.transparent ? "0" : `solid 1px ${props.theme.colors.green}`)};;
`;

const Body = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  background: white;
  margin-top: 100px;
  @media (max-width: 700px) {
    padding-top: 10px; 
  }
  z-index: 1;
`;

