import React, { Component } from 'react';
import styled , { 
  keyframes, 
  css, 
  ThemeProvider } from 'styled-components';
 
import { 
  Redirect, 
  Link,
  BrowserRouter, 
  Route, 
  Switch, 
  withRouter} from 'react-router-dom';

const _ = require('lodash');
//Code
const CARD_HEIGHT= "430px"
//Icons
const IconRoundClose = styled.div`
    color: red;
    position: absolute;
    top: 1rem; 
    right:1rem;
    font-size: 1.5rem;
    color: #000000;
    opacity: 0.54;
`;

const Container = styled.div`
    width: 70%;
    position: relative;
    margin: 0 auto;
    padding: 50px 0;
    text-align: left;
    margin-top: 100px;  
    @media (max-width: 700px) {
    width: 90%;
    margin: 2rem auto;
    }  
`;

const TextContainer = styled.div`
    width: 30%;
    position: relative;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 700px) {
    width: 100%;
    margin: 2rem 0;
    }  
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  color:#202124;
  text-align: left;
  line-height: 1;
  @media (max-width: 700px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.h2`
  margin:12px 0;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #5F6469;
    text-align: left;
    text-decoration: none;
    a{
       color: #5F6469;;
    }
`;

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
   @media (max-width: 700px) {
   display: block;
    }  
`;

class App extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  
  authWithGoogle() {
    
  }

  componentWillMount() {
    
  }
  render() {

    return (
      <React.Fragment>
        <Container>
          <Row> 
        
            <TextContainer >
              <Title>
                AdelantaT
              </Title>

              <Subtitle>
                Servicio de adelanto de nómina online.
              </Subtitle>
              <Subtitle>
                Todos los derechos reservados y compartidos en <Link 
                  to="/copyright">Derechos de imagen y copyright.</Link>
              </Subtitle>
          
         
            </TextContainer>     

            <TextContainer >
              <Title>
                Servicio


              </Title>

              <Subtitle>
                <a 
                  style={{textDecoration: "none"}}
                  href="/#inicio">
                  Inicio 
                </a>
              </Subtitle>
              <Subtitle>
                <a 
                  style={{textDecoration: "none"}}
                  href="/#como">
                  Cómo funciona
                </a>
              </Subtitle>
              <Subtitle>
                <a 
                  style={{textDecoration: "none"}}
                  href="/#cobro">
                  Cobros
                </a>
              </Subtitle>
              <Subtitle>
                <a 
                  style={{textDecoration: "none"}}
                  href="/#ASK">
                  Preguntas fecuentes
                </a>
              </Subtitle>
          
         
            </TextContainer>  


            <TextContainer >
              <Title>
                Legal
              </Title>
              <Link 
                style={{textDecoration: "none"}}
                to={"/privacidad"}> 
                <Subtitle>
                  Políticas de privacidad 
                </Subtitle>
              </Link>
              <Link 
                style={{textDecoration: "none"}}
                to={"/terminos"}>
                <Subtitle>

                  Términos y condiciones
                </Subtitle>
              </Link>
         
            </TextContainer>  
          </Row>
        </Container>
        
      </React.Fragment>
    );
  }
}



export default App;