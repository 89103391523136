import React, { Component } from 'react';
import styled from 'styled-components';
import Toolbar from "Views/Landing/Components/Molecules/Toolbar"
import Footer from "Components/Molecules/Footer"


class Copyright extends Component{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  
  authWithGoogle() {
    
  }

  componentWillMount() {
    
  }
  render() {

    return (
      <React.Fragment>
        <Body>
          <Toolbar  landing={true}/>
          <Container>
            <h1>TÉRMINOS DE USO</h1>
            <br/>
            <br/>
            <p>
              <h3>TÉRMINOS Y CONDICIONES GENERALES DE USO</h3>

              MXR Services S. de R.L., (en lo sucesivo “ADELANTAT”), con domicilio en Isabel Prieto 796, Italia Providencia, 44648 Guadalajara, Jalisco, por este medio describe los Términos y Condiciones generales de uso de su Aplicación Web (en adelante “Página”) en la cual se puede encontrar en forma enunciativa mas no limitativa, información, productos, servicios, y contenidos diversos.
              Los presentes Términos y Condiciones Generales de Uso (en lo sucesivo “Términos” y/o “Condiciones”) tienen un carácter obligatorio y vinculante; todo usuario deberá abstenerse de utilizar la Página y/o los servicios ofrecidos por ADELANTAT en caso de no aceptarlos. Toda aplicación o gestión dentro de la Página se entenderá como la aceptación expresa de los presentes Términos y Condiciones.
              Como usuario tiene la obligación de leer, entender y aceptar todas las condiciones establecidas en los Términos, en el Aviso de Privacidad y en los demás documentos análogos que impliquen una referencia directa o indirecta a los primeros, antes de registrarse como usuario o realizar cualquier gestión dentro de la página.
            </p>

            <br/>
            <h3>LEA ESTOS TÉRMINOS Y CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS.</h3> 
            <br/>
            <p>
              I. USO Y RESTRICCIONES 
              Mediante el acceso y uso de la Página o los productos de ADELANTAT, está acordando vincularse jurídicamente por estos Términos y Condiciones, que pueden establecer una relación contractual entre usted y ADELANTAT. Si no acepta dichos Términos y Condiciones, no podrá acceder o usar los Servicios, mismos que estarán únicamente disponibles para personas que gocen con la capacidad legal para contratar y para quienes no hayan sido vetados definitiva o parcialmente por ADELANTAT a su sola discreción, en relación a su comportamiento crediticio, comercial, laboral o personal.
              ADELANTAT podrá poner fin de inmediato a estos Términos y Condiciones o cualquiera de los Servicios, dejar de ofrecer o denegar el acceso a los mismos o cualquier parte de ellos, en cualquier momento y por cualquier motivo sin necesidad de aviso previo.
              Se podrán aplicar condiciones suplementarias a determinados Servicios como políticas para una actividad o una promoción particular, dichas condiciones suplementarias se comunicarán en relación con los Servicios aplicables además de los Términos y Condiciones y se considerarán una parte más de éstas para los fines aplicables, dichas condiciones suplementarias prevalecerán sobre las Condiciones en el caso de conflicto con respecto a los Servicios aplicables.
              Para solicitar cualquiera de los Servicios de ADELANTAT, deberá completar el formulario correspondiente con información personal de manera precisa, verdadera, libre de vicios o de mala fe, asumiendo el compromiso de monitorear y actualizar sus datos personales cuando sea necesario. ADELANTAT no se responsabilizará bajo ninguna circunstancia de la imprecisión de los datos personales que brinde, reservándose la facultad de requerir documentación comprobatoria o alguna verificación adicional por lo que de manera implícita usted otorga el consentimiento para ello.
              La recopilación y el uso que se hace de la información personal en relación con los Servicios es conforme a lo dispuesto en el Aviso de Privacidad para Clientes/Usuarios, disponible en www.adelantat.com/AvisoPrivacidad para su consulta.
            </p>
            <br/>

            <p>
              II. MODIFICACIONES 
              ADELANTAT podrá modificar libremente y en cualquier momento los Términos y Condiciones cuando lo considere oportuno. Las modificaciones serán efectivas después de la publicación por parte de ADELANTAT de dichos Términos y Condiciones actualizados en su Página, así como la modificación a políticas o condiciones suplementarias sobre el Servicio que aplique. El acceso o uso continuado de la Página o los Servicios después de dicha publicación constituye su consentimiento a los Términos y Condiciones y sus modificaciones siendo la última versión publicada la que regulará inmediatamente las relaciones comerciales que se generen al momento de realizarse cualquier tipo de operación.
              <br/>
            </p>
            <br/>

            <p>
              III. OBJETO 
              Los presentes Términos y Condiciones regulan la autorización de uso que otorga ADELANTAT a los Clientes y Usuarios para que ingresen a su Página, se informen sobre los Servicios que ADELANTAT ofrece y en su caso soliciten alguno de ellos.
              La celebración de la relación contractual se da únicamente entre ADELANTAT y Clientes personas físicas de nacionalidad mexicana, mayores de edad con capacidad legal para contratar. El proceso de adquisición de los Servicios de ADELANTAT es siempre a través del sitio web www.adelantat.com y nunca de forma presencial o a través de algún intermediario. El Cliente y/o Usuario al utilizar la plataforma de www.adelantat.com, declara que actúa por cuenta propia y que el producto o servicio solicitado será para uso personal y nunca para o a nombre de un tercero.
              <br/>
            </p>
            <br/>

            <p>
              IV. CUENTA DE USUARIO 
              Sujeto al cumplimiento de los presentes Términos y Condiciones, los Clientes y/o Usuarios crean una cuenta de usuario personal vinculada a través de su correo electrónico y un número de teléfono celular, que es exclusiva e intransferible para realizar los procesos de adquisición de productos o servicios de ADELANTAT en la Página, misma que se verificará mediante los mecanismos que para tal fin se tengan disponibles por lo que los Clientes y/o Usuarios asumen dicha responsabilidad y se comprometen a no ceder los datos de validación para el acceso a su perfil o para la creación de perfiles. ADELANTAT se reserva cualquier derecho que no haya sido expresamente otorgado por los presentes Términos y Condiciones.
              En caso de olvido de los datos de validación o de usurpación de éstos, es obligación del Cliente y/o Usuario informar a ADELANTAT durante el Inicio de Sesión a través de la opción “¿Olvidaste o no tienes contraseña?” o mediante el envío de un correo electrónico a la cuenta de soporte@adelantat.com para solicitar su restitución.
              Es de la entera responsabilidad del Cliente y/o Usuario mantener de forma confidencial y en un lugar seguro su usuario y contraseña para acceder a su perfil en www.adelantat.com, por lo que mediante el presente instrumento el Cliente y/o Usuario deslinda a ADELANTAT de toda responsabilidad por la pérdida, intrusión, usurpación, robo de identidad o daño alguno sin importar su alcance o naturaleza, que resulte del acceso no autorizado al perfil del Cliente y/o Usuario.
              <br/>
            </p>
            <br/>

            <p>
              V. PÁGINA WEB
              La información mostrada en la Página de ADELANTAT es meramente con fines informativos. El material que se encuentra en la Página así como en estos Términos y Condiciones está sujeto a cambios sin previo aviso, que estarán visibles tanto en la Página como en los Términos y Condiciones con la última fecha de actualización señalada.
              El acceso al Servicio puede ser limitado, con lentitud o verse afectado debido a una serie de factores como lo son:
              Funcionamiento de los servidores que alojan la Página, redes de conectividad, fallas en el hardware incluyendo el dispositivo por medio del cual se accede a la Página, líneas y conexiones de telecomunicaciones y otros equipos electrónicos y mecánicos; falla del software que incluye, entre otras cosas, errores, virus, intrusiones no autorizadas, problemas de configuración, incompatibilidad de sistemas, utilidades o aplicaciones, el funcionamiento de cortafuegos o programas de detección, códigos ilegibles o irregularidades dentro de documentos particulares u otro contenido; sobrecarga de capacidades del sistema; daños causados ​​por clima severo, terremotos, guerras, insurrecciones, disturbios, conmociones civiles, hechos fortuitos, accidentes, incendios, daños por agua, explosiones, averías mecánicas o desastres naturales; interrupción (ya sea parcial o total) de fuentes de alimentación u otra utilidad del servicio; huelga u otra detención (ya sea parcial o total) del trabajo; restricciones gubernamentales o regulatorias, resoluciones cambiarias, órdenes judiciales u otras formas de intervención humana; o cualquier otra causa ya sea similar o diferente a cualquiera de las anteriores, que sea ajena al control de ADELANTAT.
              Podrán mostrarse enlaces a una página diferente a ADELANTAT que se proporcionan únicamente como indicadores de información en temas que podrían ser útiles a usuarios del Servicio. En este sentido, ADELANTAT no tiene el control del contenido en esas páginas. ADELANTAT no garantiza el contenido en dichas páginas, incluida la precisión, integridad y confiabilidad de los sitios, así como que dicho contenido se encuentre libre de reclamos de derechos de autor, marcas registradas u otras infracciones relacionadas a los derechos de un tercero o a que dicho contenido esté libre de virus o alguna otra contaminación.
              Si decide seguir el enlace a un sitio web no controlado por ADELANTAT debe hacerlo bajo su propio riesgo. ADELANTAT no garantiza la autenticidad de los documentos de Internet. Los enlaces de páginas web que no sean controladas por ADELANTAT no implican ninguna responsabilidad por las opiniones, ideas, productos, información o servicios ofrecidos en dichos sitios ni ninguna representación referente al contenido de los mismos.
              <br/>
            </p>
            <br/>

            <p>
              VI. PROPIEDAD INTELECTUAL 
              ADELANTAT y todas aquellas marcas de su propiedad son Marcas Registradas, protegidas por la Ley de la Propiedad Industrial tanto en México como en el extranjero según las leyes aplicables. La utilización, difusión, exhibición, explotación, comercialización o cualquier otro uso, sea parcial o total, de forma idéntica o que confunda en menor o mayor grado; sea por cualquier medio, incluyendo mas no limitándose al impreso, magnético, óptico, electrónico o informático, está expresamente prohibido sin previa autorización por escrito del titular de los derechos de autor y/o marca correspondiente que en el caso concreto es ADELANTAT. Cualquier contravención a lo anteriormente expuesto o la legislación aplicable en materia de propiedad intelectual, industrial y derechos de autor será considerada y perseguida como un delito penal de comisión directa.
              El logotipo, diseños, formas, marcas nominativas, innominadas y mixtas de ADELANTAT así como cualquier material estático o interactivo incluido en la Página, está debidamente registrado ante las autoridades competentes y son propiedad de ADELANTAT. Los derechos de propiedad intelectual correspondientes a los Productos y Servicios, así como los materiales distintivos y dominios de la Página, los derechos de uso y explotación correspondientes mismos que incluyen mas no se limitan a su publicación, reproducción, divulgación, transformación y distribución son propiedad exclusiva de ADELANTAT. El usuario no adquirirá bajo ninguna circunstancia concesión o derecho alguno sobre la propiedad intelectual e industrial de ADELANTAT por el mero uso de su Página o de los Servicios ofrecidos en las mismas; por lo tanto, su uso no podrá ser considerado como una autorización tácita o expresa para utilizar alguno de los elementos de la propiedad intelectual o de derecho de autor de ADELANTAT con algún fin diverso a los contemplados por el presente documento.
              <br/>
            </p>
            <br/>

            <p>
              VII. CONSENTIMIENTO 
              El Cliente y/o Usuario acepta que la relación contractual que se puede llegar a generar por el uso de la Página o cualquiera de los Productos ofrecidos será directamente con ADELANTAT y consistirá en una prestación de servicios celebrada por medios electrónicos, en la cual el Cliente es el solicitante y/o deudor y ADELANTAT es el prestador del servicio.
              En virtud de las condiciones de capacidad legal establecidas en la legislación mexicana vigente y de la validez de la manifestación expresa de voluntad a través de medios electrónicos establecida en los artículos 89 y 90 del Código de Comercio, como Cliente y/o Usuario al momento de la creación de tu Cuenta manifiesta expresamente su consentimiento y contar con la capacidad suficiente para celebrar contratos que se pueden o pudieren llegar a realizar accediendo y usando la Página, los Productos o Servicios de www.adelantat.com. Así mismo El Cliente y/o usuario acepta, autoriza y faculta a ADELANTAT para que en su nombre y representación pueda a su vez solicitar información complementaria de sus hábitos de consumo, comportamiento crediticio y de pago. En este sentido, la aceptación de los presentes Términos y Condiciones, así como de los contratos de adhesión respectivos de cada Producto es el medio por el que manifiesta su consentimiento expreso de someterse a los mismos.


            </p><br/>
            <p>
              VIII. INDEMNIZACIÓN 
              El Cliente y/o Usuario indemnizará y mantendrá indemne a ADELANTAT, sus filiales, empresas controladas y/o controlantes, directivos, administradores, representantes y empleados, por cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en la Página o por su incumplimiento de los Términos y Condiciones Generales y demás Políticas que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros, incluyendo los honorarios de abogados en una cantidad razonable.


            </p><br/>
            <p>
              IX. DOMICILIO Y LEGISLACIÓN APLICABLE 
              Se fija como domicilio de ADELANTAT el ubicado en Calle Isabel Prieto 796, Colonia Italia Providencia, Guadalajara, Jalisco, México, C.P. 44648. Para lo no previsto en estos Términos y Condiciones, los Clientes y/o Usuarios acuerdan someterse a las leyes aplicables de los Estados Unidos Mexicanos.
              Para la interpretación y ejecución de los presentes Términos y Condiciones, el Cliente o Usuario acepta someterse a la jurisdicción y competencia de los tribunales competentes de Zapopan, Jalisco, México, renunciando expresamente al fuero que pudiese corresponderle por razón de su domicilio presente o futuro.
            </p>
            <br/>
            <p>
              X. ADVERTENCIAS 
              Incumplir sus obligaciones puede generar comisiones, gastos adicionales y actividades de cobranza. Contratar servicios de deuda por arriba de su capacidad de pago puede afectar tu historial crediticio.
            </p>
            Fecha de última actualización: 16 de Enero de 2019.

          </Container>
          <Footer />
        </Body>
      </React.Fragment>
    );
  }
}
                

export default Copyright


const Container = styled.div`
  width: 70%;
  position: relative;
  margin: 0 auto;
  text-align: left;
`;

const Body = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  background: white;
  margin-top: 100px;
  @media (max-width: 700px) {
  padding-top: 10px; 
  }
  z-index: 1;
`;
