import React, { Component } from 'react';
import styled from 'styled-components';



import SolicitudCard from "Components/Molecules/SolicitudCard";
import PropuestaCard from "Components/Molecules/PropuestaCard";


import RoundPublic from "react-md-icon/dist/BaselineTimelapse";
import BaselineCheck from "react-md-icon/dist/BaselineCheck";
import BaselineCancel from "react-md-icon/dist/BaselineCancel";
import BaselinePayment from "react-md-icon/dist/BaselinePayment";
import BaselineMonetizationOn from "react-md-icon/dist/BaselineMonetizationOn";

const ICONS = [
  ({style})=><BaselineCancel style={style}/>,
  ({style})=><RoundPublic style={style}/>,
  (style)=><BaselineCheck style={style}/>,
  (style)=><RoundPublic style={style}/>,
  (style)=><BaselinePayment style={style}/>,
  (style)=><BaselineMonetizationOn style={style}/>,
]


function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.error(e)
  }
};




const ESTADOS = [
  "Rechazado", // 0 or undefined is cancel
  "En Espera de confirmación", //1
  "Aceptada",
  "En proceso de depósito",
  "Adelanto depositado",
  "Adelanto pagado",
]

const COLORS = [
  "red", // 0 or undefined is cancel
  "#FF8F00", //1
  "green",
  "blue",
  "green",
  "gray",
]

const Button = styled.div`
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    z-index: 0;
    border-radius: 5px;
    position: relative;
    margin: 1rem auto;
    background: white;
    color: #212121;
     box-shadow: 0 0.3px 5px rgba(0,0,0,0.2);
    
      @media (max-width: 700px) {
               
           height: auto;
          
           }
          
 z-index:1;

  &:hover{
   transform: scale(1.01);
    box-shadow: 0 0.5px 7px rgba(0,0,0,0.3);
  }
  
`;

const Content = styled.div`
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;  
`;

const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
   @media (max-width: 700px) {
          width: 100%;
         }
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
               
           }
          
          
        `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}
`;

const Title = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;

         @media (max-width: 700px) {
            font-size: 20px;
         }
`;

const Subtitle = styled.div`
  font-weight: 500;
  text-align: left;
  color: #5F6469;
  font-size: 16px;
  margin: 0 0.5rem;
`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;

         @media (max-width: 700px) {
          display: none;
         }
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  

  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}


         @media (max-width: 700px) {
        margin-top: 1rem;
        width:100%;
         }
`;



const Title2 = styled.div`
width: 100%;

display: block;
font-size: 19px;
font-weight: 600;
color: #313033;
text-align: left;

`;

const Subtitle2 = styled.div`
width: 100%;
text-aling: left;
display: block;
font-size: 16px;
color: #5F6468;
font-weight: 300;

`;

const Content2 = styled.div`
  ${props=>props.theme.default.utils.rowContent}
  width: 95%;
  margin: 1rem auto;
  padding: 2rem 1rem;
     @media (max-width: 700px) {
          display: block;
         }

`;




export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;

export const PrivacyTab = styled.button`
margin-left: 1rem;
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  ${IconCirlce}{  
    height: 30px;
    width: 30px;
  }
 
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;

export const PrivacyText = styled.div`
    margin: 0 0.4rem;
     font-weight: 400;
  font-size:13px;
`;
		
const _ = require('lodash');

class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      showSolicitud: false,
      showPropuesta: false,
      loading: true
    }
  }

 
  

  render()
  {



    const {
      status= 1, 
      id="0", 
      monto="", 
      montoAprobado,
      fechaAprobada,
      folio,
      fecha,
      empresa ="",

      referencia="",

      convenio} = this.props;

    const CurrentIcon = ICONS[status]
    return (
      <React.Fragment>
        <Button zindex = {this.state.showSolicitud || this.state.showPropuesta}>
          <Content>
            <AlignStart>
              <Item2
                hide
                style={{width: "250px"}}>
    
                <Title>
           
                  Folio #{folio}
                </Title>
            
        
        
              </Item2>

              <Item2
                hide
                style={{width:"10px"}}>
                <Separator />
              </Item2>


        
        
        
              <Item2 style={{width:"500px"}}>

                <PrivacyTab style={{cursor:"not-allowed"}}> 

                  <PrivacyTabContainer>
                    <IconCirlce>
                      <CurrentIcon style={{color:COLORS[status]}}/> 
                    </IconCirlce> 
                    <Subtitle style={{color:COLORS[status]}}> {ESTADOS[status]}
                    </Subtitle>
                  </PrivacyTabContainer>

                </PrivacyTab>
          


              </Item2>



            </AlignStart>
    
            <AlignEnd>
      
            </AlignEnd>

          </Content>

          <Content2>
            <AlignStart>
              <Item2 style={{width: "35%"}}> 
                <Title2>
                  Datos de solicitud
                </Title2>
  
              </Item2>
              <Item2 style={{width: "60%"}}> 
                <Subtitle2>Folio: #{folio}</Subtitle2>
                <Subtitle2>Fecha: { 
                  (()=>{
                    const d = new Date(parseInt(fecha))
                    return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()} `
                  })()
                }</Subtitle2>
                <Subtitle2>Cantidad solicitada: ${formatMoney(monto)}</Subtitle2>
                { (montoAprobado && status !== 1)  && <Subtitle2>Monto aprobado: ${formatMoney(montoAprobado)}</Subtitle2>  }
                { fechaAprobada && <Subtitle2>Fecha de pago: {fechaAprobada}</Subtitle2>  }
                { empresa && <Subtitle2>Empresa: {empresa}</Subtitle2>  }
                { referencia && <Subtitle2>Nombre de referencia: {referencia}</Subtitle2>  }
              </Item2>


            </AlignStart>
            <AlignEnd>
      
            </AlignEnd>

  

          </Content2>

          <Item style={{width:"100%", borderTop:"solid 1px rgba(0,0,0,0.1)", height:"60px"}}> 
            {status === 2?

              <a
                href="#" 
                onClick={(e)=>{
                  e.preventDefault()
                  this.setState({showPropuesta: true})}}
                style={{color:"#00C853"}}
              >
                Confirmar Propuesta
     
              </a>
              :<a
                  href="#" 
                  onClick={(e)=>{
                  e.preventDefault()
                  this.setState({showSolicitud: true})}}
                  style={{color:"#00C853"}}
              >
                Abrir solicitud
     
              </a>}
          </Item>

        </Button>

        {this.state.showSolicitud && <SolicitudCard 
          close={()=>{this.setState({showSolicitud: false})}}
          id={this.props.id}/>}

        {this.state.showPropuesta && <PropuestaCard 
          close={()=>{this.setState({showPropuesta: false})}}
          id={this.props.id}/>}


      </React.Fragment>
    );   
  }
}


/*
{status === 2 &&

<div>
<h1>Enhorabuena se aprobó su adelanto, acepte para continuar.</h1>
<button style={{color:"white",background:"red"}}>Cancelar</button>
<button style={{color:"white",background:"purple"}} onClick={()=>{


//    updateSolicitud({id:props.id,status:3})
aceptarSolicitud(props)

}}>Aceptar</button>
</div>
}

*/


export default Modal