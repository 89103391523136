import React, { Component } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import firebase from "firebase"

const _ = require('lodash');

const Button = styled.div`
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    height: 65px;
    border-radius: 5px;
    position: relative;
    margin: 1rem auto;
    background: white;
    color: #212121;
    border: 1px gray dashed;
`;

const Content = styled.div`
  ${props=>props.theme.default.utils.rowContent}
  width: 95%;
  margin: 0 auto;
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;
    min-width: 0;
    height: 100%;
    flex: 1;
    z-index: 1;

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

const AlignEnd = styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;

const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
           }
    `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
      `}
  span{
    margin: 0 1rem;
  }
`;



const Title = styled.div`
    width: 100%;
    display: block;
    font-size: 16px;
    color: #313033;
    text-align: left;

`;

const Subtitle = styled.div`
    width: 100%;
    text-aling: left;
    display: block;
    font-size: 12px;
    color: #898989;
`;
    
const ProgressBar = styled.div`
    width: ${props=>props.width}%;
    height: 5px;
    position: absolute;
    top:0;
    left:0;
`;

const Label = styled.label`
    input[type="file"] {
        display: none;
    }
    cursor: pointer;
`;

class Uploader extends React.Component 
{
    
  constructor(props)
  {
    super(props);
    this.state = {
      progress: 0, 
      loading: false,
      filename:"",
    } 
  }

  componentDidMount(){

    this.setState({ filename: this.props.file})
    this.props.onChange(this.props.file, null)
  }
    
    
  uploadFile(file)
  {
    this.props.onChange(file.name, file)
    this.setState({ filename: file.name})
    /*
        this.refS = firebase.storage().ref("users_files/" //+userID
        + file.name)
        this.task =  this.refS.put(file)
        this.setState({loading: true})
        this.task.on("state_changed",
        //progress  
        (snapshot)=>{
         const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
    //error
    (error)=>{
        console.error("Error with file", error)
    },
    //complete
    (data)=>{
        //Set file flag to true
        this.setState({loading: false, filename: file.name}) 
        this.props.onChange(file.name)
    }
    )
    */  
  }
     
  render(){

    return (  
      <Button>
        {!this.state.loading? 
          <Content>
            <AlignStart>
              <Item>
                <span>
                  👨‍💻  </span> 
              </Item>

              <Item> 
                <Title>
                  {this.props.title}
                </Title>
              
              </Item>
            </AlignStart>

            <AlignEnd>
              <Item 
                style={{
                  color:"#3700B3", 
                  fontSize:"14px"}}>
                <Label>
                  <input
                    type="file"
                    accept="image/*|MIME_type" 
                    onChange={(e)=>{this.uploadFile(e.target.files[0])}}/>
                  {this.state.filename? this.state.filename  : "Seleccionar archivo"}
                </Label>

              </Item>
            </AlignEnd>
          </Content>  
          :
          "Subiendo Archivos, porfavor espere. ⏳"
        }
      </Button>
    )
  }       
}

export default Uploader