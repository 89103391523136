import React, {Component, Fragment} from 'react';
import { Container, PseudoContainer, Content, Description, ImageContainer, Image, Information, Name, Email, AccountIcon, ExploreIcon, SettingsIcon, ExitIcon} from './elements.js';
import BaselineAccountCircle from "react-md-icon/dist/BaselineAccountCircle";
import ListItem from './ListOptions/ListItem/';
import LineBreak from './ListOptions/LineBreak/';

class ProfileOptions extends Component {
  render() {
    return(
      <Fragment>
				
        <Content>
          <br/>
				
          <ListItem
            link="/dashboard/usuario" 
            icon={<AccountIcon/>}
            description="Usuario"/>
          <br/>

          <LineBreak/>
          <ListItem
            link="/logout"
            icon={<ExitIcon/>} 
            description="Cerrar sesión"/>
        </Content>
      </Fragment>
    )
  }
}

export default ProfileOptions;
