import Rebase from "re-base";
import firebase from "firebase";

export async function uploadFiles(Files, stepCallback = null)
{
  for (var i = Files.length - 1; i >= 0; i--)
  {
    const file = Files[i]
      
    const URL = `users_files/${firebase.auth().currentUser.uid}/${file.name}`
    const ref = firebase.storage().ref(URL)
    await ref.put(file).then((data)=>{
      if(stepCallback)
        stepCallback(data)
    }) 
  }
}