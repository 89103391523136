import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter} from 'react-router-dom';
import firebase from "firebase"
import Rebase from "re-base"
import Navbar from "./Components/Molecules/Navbar"
import Empty from "./Components/Molecules/Empty"
import Loading from "Components/Molecules/Loading";

const Inicio = withRouter((props)=> {
  class App extends Component 
  {
    constructor()
    {
      super()
      this.state = {
        createModal: false,
        onBoarding: true,
        requests: [],
        solicitud: null,
        loading: true,
       
      }
      this.base = Rebase.createClass(firebase.database());
    }

    componentWillUnmount(){
      if(typeof this.shutDownSolicitud === "function")
        this.shutDownSolicitud()
    }
     
    componentDidMount(){
      if(firebase.auth().currentUser){
        this.base.fetch('profile/'+firebase.auth().currentUser.uid,{
        }).then((data)=>{ 
          if(data.ultimaSolicitud)
          {
            this.shutDownSolicitud = this.base.syncState(`solicitudes/${data.ultimaSolicitud}`, {
              context: this,
              state: "solicitud",
              then(){
                this.setState({loading: false})
              }
            })
          }
          else
            this.setState({loading: false})
        }
        )
      }
      else
        alert("Hay un error con tu conexion, inicia sesion nuevamente o recarga el navegador.")
    }
 
    render()
    {    

      if(this.state.loading) 
        return (<Loading text="Cargando..."/>)



      return(
        <Body>
          <Navbar/>
          <Empty 
            solicitud={this.state.solicitud}
          />
        </Body>
      ) 
    }
  }
  return(<App {...props}/>)              
}
)
export default Inicio


const Body = styled.div`
width: 90%;
position: relative;
margin: 0rem auto;
h1{
    background: white;
    width: 50%;
    margin: 0rem auto;
    text-align: center;
 }
   @media (max-width: 700px)
    {
     width: 100%;
     
    }
    z-index: 3;
   
`;