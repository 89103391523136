import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link, Route,  withRouter} from 'react-router-dom';

import HelpIcon from "react-md-icon/dist/RoundHelpOutline";

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin: 0 0.5rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : props.theme.colors.green)};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border: ${props => (!props.transparent ? "0" : `solid 1px ${props.theme.colors.green}`)};;
`;
		
const _ = require('lodash');
const Toolbar = withRouter((props)=> {
                     
  const UserCirlce = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
`;

  const Logo = styled.img`
    padding-top:3px;
    width: 23px;
    margin-left: -10px;
`;

  const Nav = styled.nav`
    top:0;
    left:0;
    position: fixed;
    overflow: hidden;
    width: 100%;
    background: white;
    color: ${props=>props.theme.default.color.navbarText};
    height: 60px;
    ${props=>props.theme.default.utils.rowContent}
    z-index: 1;
    box-shadow: 0 0.5px 2px rgba(0,0,0,0.2);
   
`;

  const NavContent = styled.nav`
    ${props=>props.theme.default.utils.rowContent}
    width: 100%;
    margin: 0 15%;
     @media (max-width: 700px) {
   margin: 0 5%;
  }
`;

  const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 0;
    height: 100%;
    flex: 1;
    z-index: 1;
`;

  const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

  const AlignEnd = styled(_Section)`
    justify-content: flex-end;
    order: 1;
`;

  const Item = styled.div`
    position: relative; 
    height: 60px;
    color:${props=> props.active?"black":"#5F6469" };
    ${props=>props.theme.default.utils.centerContent}
    ${props=>props.cursor && "cursor: pointer;"}
    ${props=>props.margin && "margin: 0 0.5rem;"}
    ${props=>props.hideBig && `
        display: none;
        @media (max-width: 700px)
        {
            ${props.theme.default.utils.centerContent}
        }
    `}
    ${props=>props.hide && `
        @media (max-width: 700px)
        {
          display: none;
        }
    `}
    a{
        text-decoration: none;
        color:${props=> props.active?"black":"#5F6469" };
        font-size: 18px;
    }
`;
  const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  height: 1.2rem;
  opacity: 0.5;
`;
  const Icon = styled.i`
  vertical-align: bottom;
`;
  const TabSet = styled.div`
 display: ${props=>props.notNavbar?"none": "contents"};
  @media (max-width: 700px) {
    display: none;
  }
`;

  const TabItem = ({children, active, onClick, hide})=>{
    const TabSelector = styled.div`
    background: ${(props)=>props.theme.colors.green};
    position: absolute;
    width: 65%;
    height: 4px;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    bottom: 0;
    left:17.5%;
`;

    return (
      <Item 
        style={{margin:"0 0.8rem"}}
        onClick={onClick} 
        active={active}
        hide={hide}
        cursorPointer
      >
        {children}
        {active &&  <TabSelector/>}
      </Item>
    )
  }


  class Header extends Component
  {
    constructor(props) 
    {
      super(props)
      this.state = {
        sidebar: false
      }
    }
  
    render() {
      const tab = this.props.location.pathname
      return (    
        <Nav >
          <NavContent>
            <AlignStart>
            
         


              <Item  
            
                style={{ 
                  fontSize:"30px", 
                  fontWeigth:"600",
                  marginRight:"1rem"
                }}>
                <Link
                  to="/#inicio"

                  style={{ 
                    fontSize:"30px", 
                    fontWeigth:"600",
                    "textDecoration":"none"

                  }}

                >
                  AdelantaT
                </Link>
              </Item>

          

              <TabItem 
                hide
                notNavbar={this.props.noNavigation}
                margin 
                active = {tab === ("/")}>
                <a href="/#inicio">
                  Inicio
                </a>
              </TabItem>

              <TabItem 
                margin
                hide
                active = {tab === ("/dashboard/solicitudes")}> 
                <a 
                  style={{width: "120px"}}
                  href="/#como">
                  Cómo funciona</a>
              </TabItem>

              <TabItem
                hide
                active = {tab === ("/dashboard/archivos")} 
                margin> 
                <a href="/#cobro">
                  Cobros
                </a>
              </TabItem>

              <TabItem
                hide
                active = {tab === ("/dashboard/archivos")} 
                margin> 
                <a href="/#ASK">
                  Preguntas
                </a>
              </TabItem>

        

            </AlignStart>

            <AlignEnd> 
              <Item>
                <Link 
                  style={{ 
                    textDecoration:"none",
                    color:"gray"
                  }}
                  to="/login">
                  <Button transparent>
                    Iniciar sesión
                  </Button>
                </Link>   
              </Item>

              <Item hide>
                <Link to="/registro">
                  <Button >
                    Comenzar
                  </Button>
                </Link>
              </Item>

            </AlignEnd>
          </NavContent>
        </Nav>
      );
    }
  }

  return(<Header {...props}/>)              
}
)
export default Toolbar