import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import firebase from "firebase"
import Loading from "Components/Molecules/Loading"
import {Input} from "Views/Solicitud/Components/Molecules/Form/Components/Molecules/Inputs"

function validateEmail(email) 
{
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
    
class Login extends Component 
{
  constructor(props) 
  {
      
    super(props);
    this.state={
      username: "",
      password :"",
      error: "",
      loading: false,
      ready: false,
    }
  }

  createComponent(form)
  {
       
  }
  
  logIn(){
    this.setState({loading: true})
    firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
      .catch((error) =>{
        console.error("Login error:", error)
        var e = ""
        if(error.message)
        {
          if(error.message.includes("The email address is badly formatted."))
            e = "El formato de correo es incorrecto."
          else if (error.message.includes("There is no user record corresponding to this identifier. The user may have been deleted."))
            e = "El usuario no exite."
          else
            e = "La contraseña es inválida."
        }
        this.setState({error:e, loading: false})
      });
  }


  componentDidUpdate()
  { 
    const formValidation = this.validateForm()
    if(formValidation !== this.state.ready)
    {
      this.setState({ready: formValidation})
    }  
  }

  validateForm()
  {
    if(this.state.username 
        && this.state.password.length >= 8
        && validateEmail(this.state.username) )
      return true
    return false
  }

  render()
  {
    const close = this.props.close;
    return(
      <FullScreen>

        <Link to="/">
          <PseudofullScreen onClick={close}/>
        </Link>

        <Card>
          <NavBar>
            <NavTextContainer>
              <Title>
                Inicio de sesión.
              </Title>
            </NavTextContainer>
          </NavBar>

          <Container>
            {this.state.error && 
            <label 
              style={{color:"tomato", marginBottom:"2rem"}}>
              {this.state.error}
            </label>
            }
            <form>
              <Input
                error={(()=>{
                  if(!validateEmail(this.state.username)  && this.state.username)
                    return "No es un formato valido."
                  if(this.state.inMailInUse && this.state.inMailInUse === this.state.data.mail)
                    return "Este correo ya esta en uso."
                  return null
                })()} 
                onChange={(e)=>{this.setState({username:e})}}
                value={this.state.username}
                placeholder="Correo"/>

              <br/>

              <Input 
                error={(()=>{
                  if(this.state.error && this.state.error == "The password is invalid or the user does not have a password")
                    return "La contraseña es incorrecta."
                  return null
                })()}
                onChange={(e)=>{this.setState({password:e})}}
                value={this.state.password}
                type="password"
                placeholder="Contraseña"/>
        
            </form>
          
            <br/>         
            <ButtonContainer>
              <Link to="/registro">
                <Button transparent>Registrame</Button>
              </Link>
              <Button 
                disabled={!(this.state.password && this.state.username)}
                onClick={this.logIn.bind(this)}
                shadow>Iniciar</Button>
            </ButtonContainer>
          </Container>
        </Card>
        {this.state.loading && <Loading text="Iniciando sesión..."/>}
      </FullScreen>
    );
  }
}

export default Login


const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.5);
  padding: 0rem;
   ${props=>props.theme.default.utils.centerContent}
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 0 auto;
  border-radius: 10px;
  width: 30%;
  min-height: 400px;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 5px rgba(0,0,0,0.5);
  overflow: hidden;
   @media (max-width: 700px) {
    width: 90%;  
  }
  
`;

const NavBar = styled.div`
  position: relative;
  height: 96px;
  background: white;
  ${props=>props.theme.default.utils.centerContent}
`;

const Title = styled.h2`
  margin: 1rem auto;
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  color: #313033;
`;


const NavTextContainer = styled.div`
  width: 90%;
  text-align: left;
`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 1.2rem;
  text-align: right;

`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:0;
    ${props=>props.disabled && `  
  background:#676C72;
  cursor: no-allowed;
  `} 
`;
