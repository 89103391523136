import React from 'react';
import { Redirect,  Route, Switch } from 'react-router-dom';
import {AuthenticatedRouteRedirect, AuthenticatedRoute} from "./Utils"
import Dashboard from "./Dashboard"
import Solicitud from "Views/Solicitud"
import Login from "Views/Login"
import Logout from "Components/Molecules/Logout"
import Loading from "Components/Molecules/Loading"
import {withFirebase} from "Files/Providers/Firebase"
import Toolbar from "Components/Molecules/Toolbar"
import Copyright from "Views/Copyright"
import Privacidad from "Views/Copyright/privacidad"
import scrollTop from "Files/General/scrollToTopOnMount"

class Routes extends React.Component
{
  render(){
    if(this.props.loading)
      return <Loading text="Inicializando Adelanta-T"/>

    return (
      <React.Fragment>
        <Switch>

          <AuthenticatedRouteRedirect 
            path="/dashboard" 
            authenticated={this.props.user}
            component={scrollTop(Dashboard)}
          />
          
          <AuthenticatedRouteRedirect  
            path="/create-new"
            authenticated={this.props.user}
            component={scrollTop(Solicitud)}
          />

          <Route  
            path="/copyright"
            component={scrollTop(Copyright)} 
          />
          <Route  
            path="/privacidad"
            component={scrollTop(Privacidad)} 
          />
          <Route  
            path="/terminos"
            component={scrollTop(Copyright)} 
          />

          <AuthenticatedRoute
            path="/"
            authenticated={this.props.user}
            component={() => { 
              return (<Redirect  to="/dashboard" /> )  
            }}   
          />
       
        </Switch>

        <Route  
          path="/create-new"
          render={()=> <Toolbar 
            auth={this.props.user}
            noNavigation={true} />} />

        <Route  
          path="/login"
          component={scrollTop(Login)} />
    
        <Route  
          path="/logout"
          component={scrollTop(Logout)} />
          	
      </React.Fragment>
    )
  }
}

export default withFirebase(Routes)