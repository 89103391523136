import React, { Component } from 'react';
import styled from 'styled-components';
import {  Link, withRouter} from 'react-router-dom';
import firebase from "firebase";
import ConfirmationCode from "Components/Molecules/ConfirmationCode"
import Loading from "Components/Molecules/Loading"
import {Input} from "Views/Solicitud/Components/Molecules/Form/Components/Molecules/Inputs"
import Rebase from "re-base";

const base = Rebase.createClass(firebase.database());


function validateEmail(email) 
{
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
		

const RegisterForm = withRouter((props)=> {


  //Code
  
  class Modal extends Component 
  {
    constructor(props) 
    {
      super(props);
      this.state={
        showConfirmation: false,
        loading: false,
        data:{
          name:"",
          apellido:"",
          mail:"",
          telefono:"",
          password:"",
          password2:""
        },
        ready: false,
        inMailInUse:"",
        phoneIsInUse:"",
      }
    }


    componentDidUpdate()
    { 
      const formValidation = this.validateForm()
      if(formValidation !== this.state.ready)
      {
        this.setState({ready: formValidation})
      }  
    }

    validateForm()
    {
      if(this.state.data.name
          && this.state.data.apellido
          && this.state.data.mail
          && this.state.data.telefono.length === 10
          && this.state.data.password.length >= 8
          && this.state.data.password2
          && this.state.data.password2 === this.state.data.password
          && validateEmail(this.state.data.mail)
      )return true
  
      return false
  
    }
    async  checkPhone(){

      var a = false;
      const data = await base.fetch(`phone/${this.state.data.telefono}`, {
    
        then(data){
          if(data.active)
            a = true
            (data, a, data.active)
        }
      }).catch((data)=>{

      })

      return a;
    }
 
    async createComponent(code)
    {
      this.setState({loading: true})
      if(this.state.data.password2 === this.state.data.password)
        firebase.auth().createUserWithEmailAndPassword(this.state.data.mail, this.state.data.password)
          .then(async (rest)=>{      
            await firebase.database().ref(`profile/${rest.user.uid}`).set(
              { 
                name: this.state.data.name,
                apellido: this.state.data.apellido,
                mail:this.state.data.mail,
                telefono:this.state.data.telefono,
                id: rest.user.uid,
                fecha: Date.now(),
              })
              .catch((err)=>{
                this.setState({loading: false})
                alert("Hubo un error guardando los datos del perfil, porfavor verifique la configuracion antes de realizar una solicitud.")       
              })


            await firebase.database().ref(`phone/${this.state.data.telefono}`).set(
              { 
                active: true,
              
              })
              .catch((err)=>{
                this.setState({loading: false})
                alert("Hubo un error guardando los datos del perfil, porfavor verifique la configuracion antes de realizar una solicitud.")       
              })


            await firebase.database().ref(`bitacora/${rest.user.uid}`).set(
              { 
                name: this.state.data.name,
                apellido: this.state.data.apellido,
                mail:this.state.data.mail,
                telefono:this.state.data.telefono,
                id: rest.user.uid,
                fecha: Date.now(),
                codigoRegistro: code
              })
              .catch((err)=>{
                this.setState({loading: false})
                alert("Alerta, hubo un error.")       
              })  
          })
          .catch((error)  =>{
            this.setState({loading: false})
            var errorCode = error.code; //Verify code
            var errorMessage = error.message;
            if(errorMessage.includes("The email address is already in use by another account."))
              this.setState({inMailInUse: this.state.data.mail})
            else
              alert("Hubo un error al crear tu cuenta, intenta de nuevo. ")
          });   
    }
    render()
    {

      if(this.state.loading)
        return <Loading text="Creando cuenta..."/>
      return(
        <FullScreen>
          <Link to="/">
            <PseudofullScreen />
          </Link>
          <Card>
            <NavBar>
              <NavTextContainer>
                <br/>
                <Title>
                  Regístrate para continuar
                </Title>
              </NavTextContainer>
            </NavBar>
            <Container>
              <div>
                <Input 
                  value={this.state.data.name}
                  onChange={(name)=>{this.setState({data:{...this.state.data, name }  })} }
                  placeholder="Nombre(s)"/>
              
                <Input 
                  value={this.state.data.apellido}
                  onChange={(apellido)=>{this.setState({data:{...this.state.data, apellido }  })} }
                  placeholder="Apellidos"/>
              
                <Input 
                  type={"phone"}
                  error={(()=>{
                    if(this.state.data.telefono.length !== 10 && this.state.data.telefono)
                      return "El número de teléfono debe tener 10 digitos."
                    if(this.state.phoneIsInUse)
                      return "El número ingresado ya está en uso."
                    return null
                  })()}
                  value={this.state.data.telefono}
                  onChange={(telefono)=>{this.setState({data:{...this.state.data, telefono }, phoneIsInUse: false  })} }
                  placeholder="Número de celular (10 digitos)"/>
              
                <Input 
                  error={(()=>{
                    if(!validateEmail(this.state.data.mail)  && this.state.data.mail)
                      return "No es un formato valido."
                    if(this.state.inMailInUse && this.state.inMailInUse === this.state.data.mail)
                      return "Este correo ya esta en uso."
                    return null
                  })()}
                  type={"email"}
                  value={this.state.data.mail}
                  onChange={(mail)=>{this.setState({data:{...this.state.data, mail }  })} }
                  placeholder="Correo"/>
              
                <Input
                  error={(()=>{
                    if(this.state.data.password.length < 8 && this.state.data.password)
                      return "La contraseña debe ser tener al menos 8 caracteres."
                    return null
                  })() } 
                  type={"password"}
                  value={this.state.data.password}
                  onChange={(password)=>{this.setState({data:{...this.state.data, password }  })} }
                  placeholder="Contraseña"/>

                <Input 
                  error={(()=>{
                    if(this.state.data.password2 !== this.state.data.password && this.state.data.password2)
                      return "Las contraseñas no coinciden."
                    return null
                  })() }
                  type={"password"}
                  value={this.state.data.password2}
                  onChange={(password2)=>{this.setState({data:{...this.state.data, password2 }  })} }
                  placeholder="Confirmar contraseña"/>          
              </div>

              <ButtonContainer>
                <Link to ="/">
                  <Button transparent>Cancelar</Button>
                </Link>
            
                <Button
                  disabled={!this.state.ready} 
                  onClick={async ()=>{
                    if(await this.checkPhone() === true)
                    {
                      this.setState({phoneIsInUse: true})
                    }
                    else{
                      this.setState({showConfirmation: true})
                    }
                
                  }}
                  shadow 
                >Continuar</Button>
              </ButtonContainer>
            </Container>
          </Card>
          <div style={{width: "100%", textAlign:"center", marginBottom:"2rem", "color":"gray"}}>
            <Link to="/terminos">Al usar AdelantaT aceptas nuestros terminos y condiciones.</Link>
          </div>
          {this.state.showConfirmation && 
          <ConfirmationCode
            
            close={()=>{ 

              this.setState({showConfirmation: false})

            }
            } 
            phone={this.state.data.telefono}
            continue={(code)=>{
             
              this.createComponent(code);
              this.setState({showConfirmation: false});
              

            }} 
          />
          }   
        </FullScreen>
      );
    }
  }
  return <Modal {...props}/>               
}
)
export default RegisterForm

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.5);
  padding: 0rem;
  

  z-index: 999999999999;

`;

const Card = styled.div`
  margin: 3rem auto;
  border-radius: 7px;
  width: 30%;
    padding: 0.8rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 3px rgba(0,0,0,0.3);
  
   @media (max-width: 700px) {
    width: 90%;
    
  }
  
 
`;


const NavBar = styled.div`
  position: relative;

  background: white;

  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  text-align: center;
  font-size: 25px;
  color: #313033;
`;

const NavTextContainer = styled.div`
  width: 90%;
  text-align: left;
`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 1.2rem;
  text-align: right;

`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 3px;
  overflow: hidden;

  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "black")};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:0;

  ${props=>props.disabled && `  
  background:#676C72;
  cursor: no-allowed;
  `}
`;
   

