import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter} from 'react-router-dom';
import firebase from "firebase"
import Rebase from "re-base"
import Empty from "./Components/Molecules/Empty"
import Loading from "Components/Molecules/Loading";
import ItemSolicitud from "Components/Molecules/ItemSolicitud"
const _ = require('lodash');

const SolicitudesList = withRouter((props)=> {
 
  class App extends Component 
  {
    constructor()
    {
      super()
      this.state = {
        createModal: false,
        onBoarding: true,
        requests: [],
        solicitudes:[],
        loading: true,
      }
      this.base = Rebase.createClass(firebase.database());
    }     
    componentDidMount()
    { 
      if(firebase.auth().currentUser) 
        this.base.fetch('solicitudes', {
          context: this,
          asArray: true,
          queries: {
            orderByChild: 'usuario',
            equalTo: firebase.auth().currentUser.uid,
          },  
          then(data){
            this.setState({solicitudes: data, loading: false})
          }
        });
      else
      {
        alert("Hay un error con tu conexion, inicia sesion nuevamente o recarga el navegador.")
      }
    }

    render(){ 

      if(this.state.loading)
        return <Loading text="Cargando solicitudes"/>

      return(
        <Body>
          {
            this.state.solicitudes?.length ? _.map(this.state.solicitudes, (item) => (
              <ItemSolicitud 
                {...item} 
                key={item.id} />
            )
            ) :
              (
                <Empty/>
              ) 
          }
        </Body>
      )
    }
  }
  return(<App {...props}/>)            
}
)

        


export default SolicitudesList


const Body = styled.div`
width: 70%;
margin: 1rem auto;
 @media (max-width: 700px) {
  width: 90%;
 }

h1{
  background: white;
  width: 50%;

margin: 1rem auto;
text-align: center;

}
`;
   