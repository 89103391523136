import React, { Component } from 'react';
import styled , { 
  keyframes, 
  css, 
  ThemeProvider } from 'styled-components';

import {Link } from 'react-router-dom';


const Container = styled.div`
    width: 70%;
    position: relative;
    margin: 0 auto;
    padding: 100px;
    text-align: left;
    background: #FAFAFA;
    ${props=>props.theme.default.utils.centerContent}
    @media (max-width: 700px) {
      width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 60%;
  position: relative;
  margin: 0 auto;
  text-align: left;
  padding-top: 4rem;
  @media (max-width: 700px) {
     width: 100%;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 400;
  font-size: 40px;
  width: 100%;
  color:#202124;
  text-align: left;
`;

const Subtitle = styled.h2`
   width: 100%;
    font-weight: 400;
    font-size: 17px;
    color: #5F6469;
    text-align: left;
`;

const Card = styled.div`
    width: 34%;
    height: 250px;
    background: white;
    border-radius: 3px;
    box-shadow:   0 0.3px 5px rgba(0,0,0,0.2);
    text-align: center;
    padding: 1rem;
    ${props=>props.theme.default.utils.centerContent}
    @media (max-width: 700px) {
    width: 100%;
    margin: 2rem 0;
    }  
`;

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
    @media (max-width: 700px) {
    display: block;
    width: 100%;
  }
`;
const CardTitle = styled.h1`
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #5F6469;
    text-align: center;
`;
const CardSubtitle = styled.h2`
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #202124;
    text-align: center;
`;
const CardPrice = styled.h2`
    width: 100%;
    font-weight: 400;
    font-size: 51px;
    color: ${props=>props.theme.colors.green};
    text-align: center;
`;

class App extends Component
{
  constructor(){
    super();
    this.state = {
      register: false
    }
  }
  authWithGoogle() { 
  }
  componentWillMount() { 
  }
  render() {

    return (
      <React.Fragment>
  
        <Container>
          <Row>
            <TextContainer >
              <Title>
                Nuestra comisión por adelanto.
              </Title>
              <Subtitle>
                Sin sorpresas, sin cargos ocultos, simple y directo.
              </Subtitle>
      
            </TextContainer>
            <Card>
              <CardTitle>
                Comisión por servicio.
              </CardTitle>
        
              <CardPrice>
                10%
              </CardPrice>
            </Card>
          </Row>
        </Container>      
      </React.Fragment>
    );
  }
}
export default App;