
import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";

		 //Imports.
//{getImports(data.component.imports)}
   

		

const _ = require('lodash');

const Navbar = withRouter((props)=> {


  //Code
  return <h4></h4>
                
}
)

        


export default Navbar