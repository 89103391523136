import React, { Component } from 'react';
import styled from 'styled-components';
import {  Route, Switch, withRouter } from 'react-router-dom';
import DetallesSolicitud from "Views/DetallesSolicitud";
import SolicitudesList from "Views/SolicitudesList";
import Archivos from "Views/Archivos";
import Usuario from "Views/Usuario";
import Inicio from "Views/Inicio";
import Toolbar from "Components/Molecules/Toolbar"

          
class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
    }
  }

  render()
  {
    return(
      <React.Fragment>

        <Body>
         
          <Container>
            <Switch>
              <Route  
                path={`${this.props.match.url}/configuracion`} 
                render={(props)=>(<h1>Configuracion{props.match.url}</h1>)}/>
            
              <Route  
                path={`${this.props.match.url}/solicitudes/v/:id`} 
                component={DetallesSolicitud}/>
                
              <Route 
                exact 
                path={`${this.props.match.url}/solicitudes`} 
                component={SolicitudesList}/>
                    
              <Route  
                path={`${this.props.match.url}/archivos`} 
                component={Archivos}/>
                    
              <Route  
                path={`${this.props.match.url}/ayuda`} 
                render={(props)=>(<h1>Ayuda{props.match.url}</h1>)}/>
                
              <Route  
                path={`${this.props.match.url}/usuario`} 
                component={Usuario}/>
                        
               

              <Route 
                path={`${this.props.match.url}/`} 
                component={Inicio}/>
                  
            </Switch>
          </Container>
        
       
          <Toolbar auth={true}/>   
        </Body>

        
      </React.Fragment>
    );
  }
}

export default withRouter(Modal)


const Body = styled.div`
      width: 100%;
      position: relative;
      top: 65px; 
      background: white;
      text-align: left; 
      z-index:1;
      
    `;
    
const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`;