import React, { Component } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, 
  Route, Switch, withRouter} from 'react-router-dom';

const Icon = styled.i`
  vertical-align: bottom;
`;

const Button  = styled.a`
  position: relative;
  padding:  0.3rem 0.8rem;
  margin: 0.5rem;
  border-radius: 3px;
  font-size: 16px;
  overflow: hidden;
  background: #64b5f6;
  color: white;
  cursor: pointer;
  text-decoration: none;
`;

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.8);
  padding: 0rem;
  padding-top: 5%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Sidebar = styled.div`
  width: 75%;
  position: fixed;
  top: 0;
  left:0;
  bottom:0;
  font-family: 'Rubik', sans-serif;
  background: white;
  text-aling: left;
  box-shadow:0 0.03px 5px rbga(0,0,0,0.5);
    
`;

const ContexCard = styled.div`
  width: 300px;
  text-align: left;
  background: white;
  padding: 1rem;
  border-radius: 2px;
  color: grey;
  z-index: 999999999999999;
`;

const ShowUp = styled.div`
  position: relative;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999;
  background: red;
`;
const Ul = styled.ul`
  list-style:  none;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  z-index: 999999999999999;
  font-weight: 500;
`;

const Li = styled.li`
  width: 100%;
  padding: 1rem 0;
`;

const LinkText = styled.div`
  text-decoration: none;
  color: #ffffff;
`;

const Head = styled.p`
  text-decoration: none;
  color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
  font-size: 15px;
  padding: 0 1rem;
  cursor: pointer;
  text-aling: left;
  position: relative;
  a{
      text-decoration: none;
      color:${props=>props.active?props.theme.colors.green:"#7D7582"} ;
  }
`;

const Selection = styled.label`
  position: absolute;
  top: -0.8rem;
  left: 0rem;
  height: 42px;
  width: 100%;
  cursor: pointer;
  background: #3700B3;
  opacity:0.06;
  border-radius: 0 20px 20px 0;
`;


const Footer = styled.label`
  position: absolute;
  bottom:1rem;
  font-size: 14px;
  color: grey;
  left:1rem;
  width: 100%;
`;

const _ = require('lodash');

class Notebook extends Component {

  render()
  { 
    const close = this.props.close//?this.props.close:this.props.hideOnClick;

  
    const tab = this.props.location.pathname
    return (
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Sidebar>

          <Head active = {tab === ("/dashboard")}>

            <Link to="/dashboard">
    
              <Icon className="material-icons">home</Icon>    Inicio
            </Link>
          </Head>

          <Head active = {tab === ("/dashboard/solicitudes")}>
            <Link to="/dashboard/solicitudes">
    
              <Icon className="material-icons">monetization_on</Icon>Solicitudes
            </Link>
          </Head>


          <Head active = {tab === ("/dashboard/archivos")}>
            <Link to="/dashboard/archivos">

              <Icon className="material-icons">subject</Icon>Archivos
            </Link>
          </Head>
  

          <hr/>

  

          <Footer>
            Terminos y condiciones
            <br/>
            <br/>

            Privacidad
          </Footer>
        </Sidebar>
      </FullScreen>
    )}
}

export default withRouter(Notebook)
