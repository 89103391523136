import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

String.prototype.capitalize = function(lower)
{
  return (lower ? this.toLowerCase() : this).replace(/(?:^|\s)\S/g, function(a)
  { 
    	return a.toUpperCase(); 
  });
};

Array.prototype.sortObjArray = function(key)
{
  return this.sort((a, b) => {
    if(a[key] < b[key]) return -1;
    if(a[key] > b[key]) return 1;
    return 0;
  })
}

ReactDOM.render(
  <div>
    <App />
  </div>, 
  document.getElementById('root')
);

