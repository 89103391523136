import React  from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {getUsuario} from "Files/General/DatabaseQueries/Get/Usuarios";
import FileModal from "Components/Molecules/FileModal";
import firebase from "firebase";
import Loading from "Components/Molecules/Loading";

class Archivos extends React.Component
{

  constructor(props)
  {
    super(props);
    this.state = {
      profile: null,
      loading: true,
      currentFile: null,
    }
  }

  componentDidMount(){
    const { uid } = firebase.auth().currentUser
    getUsuario(uid, (data) => { 
      this.setState({
        profile: data, 
        loading: false
      })
    })
  }


  render(){



    
  
    if(this.state.loading)
      return  <Loading text="Abriendo archivo." />


    const fileExists = this.state.profile?.files_adress || this.state.profile?.files_id || this.state.profile?.files_nomina
    
    return(
      <React.Fragment>

        <Body>
          <Title>Archivos</Title>
		
          <Row>       
            <Card 
              disabled={!this.state.profile?.files_id}
              onClick = {() => {

                if(this.state.profile?.files_id){

                  this.setState({currentFile: {
                    file:this.state.profile?.files_id,
                    title: "👤 Identificación"
                  }})}


              }} 
            >
              👤 Identificación
            </Card>

            <Card 
              disabled={!this.state.profile?.files_adress}
              onClick = {()=>{
                if(this.state.profile?.files_adress){
                  this.setState({currentFile: {
                    file:this.state.profile?.files_adress,
                    title: "✉️ Comprobante de domicilio"
                  }})
                }

              }} >✉️ Comprobante de domicilio</Card> 

            <Card
              disabled={!this.state.profile?.files_nomina}
              onClick = {()=>{
                if(this.state.profile?.files_nomina){
                  this.setState({currentFile: {
                    file:this.state.profile?.files_nomina,
                    title: "💳 Comprobante de Nomina"
                  }})
                }
              }
              }>💳 Comprobante de Nomina</Card> 

          </Row>


          {fileExists ? 
            <p style={{textAlign: 'center'}}>Crea una nueva solicitud para actualizar archivos.</p>  : 
            <p style={{textAlign: 'center'}}>Aún no tienes archivos, crea una nueva solicitud para agregar archivos.</p> }
        </Body>





        {this.state.currentFile && 
        <FileModal  
          close = {()=>{this.setState({currentFile: null})}}
          {...this.state.currentFile}/>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(Archivos)



const Body = styled.div`  
	position: relative;
	margin: 0 auto;
	width: 80%;
`;

const Row = styled.div`  
	${props=>props.theme.default.utils.rowContent}
	width: 100%;
	@media (max-width: 700px) {
          display: block;
         }
`;

const Card = styled.div`
	background: white;
	position: relative;
	width: 32%;
	height: 65px;
	margin: 0.4rem auto;
	position: relative;
	border-radius: 7px;
	overflow:hidden;
	text-align: center;
	display: inline-block;
	border: solid 1px rgba(0,0,0,0.1);
    cursor: pointer;
	border: solid 1px #DADCE0;
	${props => props.theme.default.utils.centerContent}
  ${(props) => props.disabled ? `
  
    background: gray;
    color: white;
  
  `: ''}
	@media (max-width: 700px) {
          width: 100%;
         }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 45px;
  color: #202124;
  text-align: center;

`;

export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 16px;
  color: #5F6469;
  text-align: center;
    a{
    color: #00C853;

  }
`;