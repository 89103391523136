import React, { Component } from 'react';
import styled from 'styled-components';
import {  withRouter} from 'react-router-dom';
import Toolbar from "Views/Landing/Components/Molecules/Toolbar"
import Footer from "Components/Molecules/Footer"


const Landing = withRouter((props)=> {
  class App extends Component{
    constructor(){
      super();
      this.state = {
        register: false
      }
    }
    authWithGoogle() {
    
    }

    componentWillMount() {
    
    }
    render() {

      return (
        <React.Fragment>
          <Body>
            <Toolbar  landing={true}/>
            <Container>
   
              <h1>Aviso de privacidad integral para clientes y usuarios de ADELANTAT</h1>
              <br/>
              <p>
                El presente documento tiene como fin primordial dar cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “LFPDPPP”), su reglamento, los lineamientos del Aviso de Privacidad y las demás disposiciones aplicables (en adelante denominados conjuntamente como la “Ley”) en los siguientes términos:
              </p>
              <br/>
              <p>
                A. RESPONSABLE DE LOS DATOS PERSONALES. 
                Para efectos del presente Aviso de Privacidad, MXR Services S. de R.L. (en adelante el “Responsable” o “ADELANTAT”, indistintamente), con domicilio en Isabel Prieto 796, Colonia Italia Providencia, Guadalajara, Jalisco, México, C.P. 44648 es el responsable en los términos de la Ley de la obtención, divulgación, almacenamiento y/o uso, incluido acceso, manejo, aprovechamiento, transferencia o disposición (en adelante el “Tratamiento”) de los datos personales que recabe de clientes y/o usuarios (en adelante el(los) “Titular(es)”).
              </p>
              <br/>
              <p>
                B. DATOS PERSONALES RECABADOS Y SOMETIDOS A TRATAMIENTO. 
                Se informa que ADELANTAT obtendrá datos personales ya sea de forma directa o indirecta, cuando se realiza el registro como usuario, se ingresa una solicitud y proporciona datos personales para efectos de la prestación de los servicios que ofrece ADELANTAT a través de nuestra página de Internet, dichos datos pueden obtenerse o complementarse vía telefónica, a través de un tercero o cualquier otro medio personal o electrónico disponible.
              </p>
              <br/>
              <p>
                ADELANTAT se sujeta a tratamiento los siguientes Datos Personales obtenidos a través de los medios antes descritos:
                Datos de identificación
              </p>
              <br/>
              Datos económicos/financieros
              <br/>
              Datos de contacto
              <br/>
              Detalles de hábitos o consumo
              <br/>
              Datos laborales
              <br/>
              Información de su teléfono móvil
              <br/>
              Datos patrimoniales
              <br/>
              <br/>
              <br/>
              <br/>
              <p>
                Estos datos podrán ser utilizados entre otros fines que conforme se describen en el apartado C del presente documento, para evaluar los riesgos inherentes al servicio que solicite, verificar identidad, evaluar y calificar capacidad de pago y para fines estadísticos.
                ADELANTAT no recaba directamente datos personales de menores de edad a través de formularios, cuestionarios, ni a través de su página web propias o de terceros. Asimismo informamos que ADELANTAT no recaba datos sensibles para su tratamiento.
                Para efectos de proporcionar datos de terceros, ya sea información para la adquisición de productos o servicios ofrecidos por socios comerciales o cualquier otro motivo, será de su entera responsabilidad notificar a los mismos sobre la transferencia de sus datos personales al Responsable. En todo caso, ADELANTAT tratará dicha información con base en las disposiciones de la Ley y este Aviso de Privacidad y, asimismo, en calidad de Responsable y en cumplimiento con las disposiciones de la Ley, se pondrán a disposición de dichos terceros el presente Aviso de Privacidad en el primer contacto que se tenga con los mismos.
                Se hace de su conocimiento que ADELANTAT no trabaja con agentes, comisionistas, ni otras figuras similares, por lo que cualquier dato que proporcione a cualquier tercero que se haga pasar como tal, no se encontrará bajo la responsabilidad de ADELANTAT y por lo tanto, usted como Titular de los datos será el único responsable de la información y datos personales que comparta y el tratamiento que se les dé bajo dichos supuestos.
              </p>
              <br/>
              C. FINALIDADES A LAS QUE SE SUJETARÁ EL TRATAMIENTO DE DATOS PERSONALES. 
              ADELANTAT hace uso de sus datos personales conforme a los siguientes fines:
              a) Finalidades originarias y necesarias para la prestación del servicio.
              Identificación del solicitante y/o cliente, según sea el caso.
              Integración del expediente de información como cliente.
              Gestión, control, administración y procesamiento de la solicitud de servicio y en su caso el otorgamiento de este.
              Gestión, control, administración, ejecución y cumplimiento servicio celebrado con el cliente conforme a la solicitud correspondiente.
              Gestión, control y administración de la cobranza administrativa (a través de cualquier medio de contacto otorgado en la solicitud), extrajudicial y, en su caso, judicial derivada de los servicios proporcionados por ADELANTAT.
              Investigación de cambios en perfil transaccional del cliente.
              Cumplimiento a obligaciones de carácter fiscal o comercial.
              Atención de consultas, dudas, aclaraciones o quejas del cliente.
              Estadística y registro histórico de usuarios y clientes.
              Creación, mantenimiento y entrenamiento de los modelos de riesgo predictivos de ADELANTAT.
              Mantener la seguridad de la información y de la operación.
              Protección ante casos de robo de identidad.
              <br/>
              b) Finalidades secundarias.
              Fines mercadotécnicos, publicitarios y/o de prospección comercial.
              Otorgamiento de estímulos o reconocimientos a clientes.
              Uso de imágenes y testimonios de clientes y/o usuarios para fines publicitarios y de ofertas comerciales referentes a productos y/o servicios ofrecidos o relacionados con productos y/o servicios contratados.
              Mejoras en el servicio de atención y trato con el cliente.
              En caso de que no desee que se utilicen sus datos para estos fines adicionales por favor seleccione la siguiente casilla:
              No deseo que mis datos sean utilizados para las finalidades secundarias descritas anteriormente.
              <br/>
              Es prerrogativa del Cliente y/o Usuario la opción de solicitar la revocación de consentimiento en cualquier momento ingresando nuevamente a este aviso de privacidad y seleccionando la casilla correspondiente, en caso de que lo haya otorgado previamente. Dicha solicitud aplica desde el momento que seleccione la casilla. Para que la revocación sea vinculada correctamente, deberá iniciar sesión en la página de www.adelantat.com y seguir el procedimiento descrito previamente. La negativa para el uso de datos personales para estas finalidades no será motivo para negar el servicio.
              <br/>
              D. OPCIONES Y MEDIOS QUE ADELANTAT OFRECE A LOS TITULARES DE DATOS PERSONALES PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES. 
              Puede limitar el tratamiento de sus datos personales de la siguiente forma:
              I. El uso, siguiendo las instrucciones indicadas en el último párrafo del apartado C.
              II. La divulgación, siguiendo las instrucciones indicadas en el apartado G.
              III. Dirigiendo la solicitud correspondiente al área de Legal y Cumplimiento para ejercer sus derechos ARCO conforme a los lineamientos establecidos en el apartado F.
              IV. En el caso de los datos personales recabados conforme al último párrafo del apartado E siguiente, podrá limitar su tratamiento conforme a lo ahí descrito.
              <br/>
              E. OTROS MEDIOS PARA RECABAR DATOS PERSONALES. 
              ADELANTAT utiliza cookies y/o web beacons para facilitar la navegación en el sitio web  www.adelantat.com. Las cookies constituyen una herramienta empleada por los servidores web para almacenar y recuperar información que se guarda en el navegador utilizado por los usuarios o visitantes del sitio web, lo que permite guardar tus preferencias personales para brindarte una mejor experiencia de navegación.
              Las cookies tienen fecha de caducidad, que puede oscilar desde el tiempo que dura la sesión o visita al sitio web hasta una fecha especifica a partir de la cual dejan de ser operativas. Las cookies empleadas en www.adelantat.com se asocian únicamente con un Usuario anónimo y su equipo informático, no proporcionan referencias que permitan deducir el nombre y apellidos del Usuario, no pueden leer datos de su disco duro ni incluir virus en sus textos.
              Puede configurar su navegador para aceptar o rechazar automáticamente todas las cookies o para recibir un aviso en pantalla sobre la recepción de cada cookie y decidir en ese momento su implantación o no en tu disco duro. Sugerimos consultar la sección de ayuda de su navegador para saber cómo cambiar la configuración sobre aceptación o rechazo de cookies, aún y cuando se configure el navegador para rechazar todas las cookies o rechace expresamente las cookies de www.adelantat.com podrá seguir navegando por el sitio web con el único inconveniente de no poder disfrutar de las funcionalidades que requieran la instalación de alguna de ellas. En todo caso, podrá eliminar las cookies instaladas en su disco duro en cualquier momento siguiendo el procedimiento establecido en la sección de ayuda de su navegador.
              Le confirmamos que ADELANTAT no recaba datos personales a través de estos medios, por lo que no se someten a tratamiento ni a transferencias. Es de suma importancia para ADELANTAT informar que nunca utilizará la información recabada por estos medios de manera individual ni para algún uso distinto al descrito. 
              <br/>
              F. MEDIOS PARA EL EJERCICIO DE DERECHOS ARCO DEL TITULAR. 
              Con el objeto de proteger los datos personales, la privacidad y confianza de nuestros clientes y usuarios, ponemos a tu disposición la dirección de correo electrónico arco@adelantat.com en donde como titular en cualquier momento puede limitar el uso o divulgación de sus datos personales, así como negar o revocar la autorización para su tratamiento, mediante el ejercicio de los derechos de acceso, rectificación, cancelación u oposición que la Ley prevé a través de:
              a) El correo electrónico arco@adelantat.com cumpliendo con todos los requisitos señalados indicados a continuación:
              El nombre y firma autógrafa del titular, así como un domicilio u otro medio para comunicarle la respuesta a su solicitud.
              Acompañar los documentos oficiales que acrediten la identidad del titular.
              Incluir una descripción clara y precisa de los datos personales respecto de los cuales ejercitará los derechos que les confiere la Ley.
              Incluir cualquier elemento o documento que facilite la localización de los datos personales de que se traten.
              Como titular usted es responsable de mantener actualizados sus datos personales en posesión de ADELANTAT por lo que usted se compromete, garantiza y responde en cualquier caso de la veracidad, exactitud, vigencia y autenticidad de los datos personales facilitados, y se responsabiliza de mantenerlos debidamente actualizados, comunicando cualquier cambio al Responsable.
              b) Mediante escrito dirigido a nuestro equipo de Legal con domicilio en Isabel Prieto 796, Italia Providencia, Guadalajara, Jalisco, México CP. 44648, acompañado del escrito y documentos conforme a lo establecido en el punto a) anterior.
              ADELANTAT le comunicará en un plazo máximo de veinte días hábiles contados desde la fecha en que reciba la solicitud correspondiente, la determinación adoptada. Si la solicitud resulta procedente, esta se hará́ efectiva dentro de los quince días hábiles siguientes a la fecha en que el Responsable comunique la respuesta.
              En caso de que la información proporcionada en tu solicitud sea errónea o insuficiente, o no se acompañen los documentos necesarios para acreditar tu identidad o la representación legal correspondiente, o cualquier otro que sea necesario para ejercer tus derechos; dentro de los cinco días hábiles siguientes a la recepción de su solicitud, se requerirá se subsanen de las deficiencias para poder dar trámite a la misma. En estos casos, tendrá diez días hábiles para atender el requerimiento de subsanación contados a partir del día siguiente en que lo haya recibido. La solicitud correspondiente se tendrá́ por no presentada si no responde dentro de dicho plazo. 
              En el supuesto de que se tuviera un servicio vencido o por vencer, ADELANTAT no podrá hacer modificaciones a sus datos personales, para ello será necesario que primeramente se liquiden las obligaciones contraídas y una vez que sea saldado seguir el proceso descrito anteriormente.
              En caso de insatisfacción o incumplimiento de parte de ADELANTAT podrá acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) para solicitar el inicio del procedimiento de protección de derechos.
              <br/>
              <p>
                G. TRANSFERENCIA DE DATOS PERSONALES. 
                ADELANTAT podrá realizar la transferencia y/o remisión de datos a:
                ADELANTAT puede transferir sus datos personales a:
              </p>
              Razones
              ¿Puede limitar o cancelar la transferencia de datos?
              Sociedades controladoras, subsidiarias o afiliadas a ADELANTAT o a una sociedad matriz
              Resguardo, control, análisis estadístico, evaluación, mejora y diseño de nuevos productos.
              No
              Terceros no afiliados (prestadores de servicios)
              Asistencia en la ejecución de los servicios contratados.
              No
              Autoridades administrativas, judiciales o gubernamentales, así́ como a Sociedades de Información Crediticia
              Por mandato judicial o administrativo o cuando una Ley lo determine
              No
              Terceros subcontratados
              Procesar información por cuenta e instrucciones de ADELANTAT.
              No
              Proveedores de manejo de riesgo, seguridad de la información y/o software especializado.
              Para la prevención de fraude.
              No
              Socios comerciales
              Para la adquisición de productos o servicios obtenidos a través de servicios ADELANTAT.
              No
              Afiliados comerciales*
              Fines publicitarios.
              Sí
              <br/>
              <p>
                Los terceros y las entidades receptoras de datos personales, asumen las mismas obligaciones y/o responsabilidades de ADELANTAT de conformidad con lo descrito en el presente Aviso de Privacidad.
                Le informamos que sus datos personales podrán ser transferidos y tratados por personas distintas a ADELANTAT en México o en el extranjero cuando se trate de: a) Cualquier prospecto, comprador potencial o adquirente de ADELANTAT o de cualesquiera de sus activos, incluyendo su cartera de servicios, adquirir las acciones o activos de ADELANTAT o sus accionistas o filiales en México; b) Cualquier autoridad o auditor interno y externo cuando así lo requieran para llevar a cabo la auditoría correspondiente; c) Cualquier proveedor o prestador de servicios de cobranza, análisis de riesgo y otros que ADELANTAT considere necesario contratar.
                Para la transferencia indicada con asterisco (*) requerimos su consentimiento, el cual otorga al aceptar este aviso de privacidad.
                En caso de que desee que sus datos no sean transferidos a afiliados comerciales, por favor seleccione la siguiente casilla:
                No deseo que mis datos sean transferidos para fines publicitarios.
                Puede solicitar la revocación de su consentimiento en cualquier momento ingresando nuevamente a este aviso de privacidad y seleccionando la casilla anterior en caso de que lo haya otorgado previamente. Dicha solicitud aplica desde el momento que seleccione la casilla. Para que su revocación sea guardada correctamente, deberá iniciar sesión en la página www.adelantat.com y seguir el procedimiento descrito previamente. La negativa respecto a la transferencia de sus datos personales para estas finalidades no será́ motivo para negar el servicio.
                Asimismo, hacemos de su conocimiento que podremos transferir tus datos sin necesidad de su autorización previa en cualquiera de los supuestos considerado en el artículo 37 de la LFPDPPP.
              </p>
              <br/>
              <p>
                H. REVOCACIÓN DEL CONSENTIMIENTO. 
                En cualquier momento, podrá revocar su consentimiento para el tratamiento de tus datos personales, siempre y cuando, dicha revocación sea legitima y no suponga la imposibilidad de cumplir obligaciones derivadas de una relación contractual vigente entre ADELANTAT y usted, o no sea posible en virtud de un mandato legal o judicial.
                Puede solicitar la revocación de su consentimiento iniciando sesión en www.adelantat.com y seleccionando las casillas deseadas. La negativa respecto al tratamiento de sus datos personales para estas finalidades y/o transferencias, no vulnerará tu relación contractual con ADELANTAT.
              </p>
              <br/>
              <p>
                I. CAMBIOS AL AVISO DE PRIVACIDAD. 
                Cualquier actualización, modificación o cambio al presente aviso de privacidad que se realice atendiendo a novedades legislativas y/o modificaciones en las políticas internas de ADELANTAT, será publicado en el sitio web www.adelantat.com/politicas.  
                Sin embargo, cuando el contenido del presente Aviso de Privacidad cambie sustancialmente, por ejemplo, si las finalidades para las cuales fueron recabados los datos personales cambiaran, le será informado y se requerirá nuevamente de su consentimiento para continuar tratando tus datos personales.
              </p>
              <br/>
              <p>
                J. POLÍTICAS DE SEGURIDAD. 
                ADELANTAT protege sus datos personales usando el estándar de la industria en materia de encriptación, de esta forma se garantiza que los datos que se envían desde la Página de Internet lleguen seguros a nuestros servidores. Se almacena y procesa la información manteniendo siempre medidas de seguridad orientadas a proteger sus datos personales.
                ADELANTAT cuenta con procedimientos que identifican quién y bajo qué condiciones puede tener acceso a los datos dando siempre la máxima prioridad a la protección de su privacidad. Asimismo, se asumen medidas de seguridad físicas para prevenir el acceso no autorizado a los sistemas e instalaciones.
              </p>
              <br/>
              <p>
                K. CONSENTIMIENTO DEL TITULAR. 
                Con la lectura y aceptación del presente Aviso de Privacidad cada vez que ingresa a su perfil de usuario por cualquiera de los medios disponibles para ello, manifiesta expresamente que: (i) el presente Aviso de Privacidad le ha sido dado a conocer por ADELANTAT y (ii) ha leído y entendido en su totalidad los términos y alcances expuestos, por lo que expresamente otorga su consentimiento para el tratamiento de sus datos personales en los términos del mismo.
              </p>
              <br/>
              <p>
                L. COMUNICACIÓN EN GENERAL
                ADELANTAT se reserva el derecho de cambiar, modificar, completamente y/o alterar el presente aviso, en cualquier momento, en cuyo caso se hará de su conocimiento a través de cualquiera de los medios que establece la legislación en la materia.
              </p>
              <br/>
              Fecha de última actualización: Enero del 2019.
              <br/>
              <p>
                Opciones y medios para ejercer los derechos ARCO:
                Presentando su solicitud a MXR Services S. de R.L, en el domicilio de Isabel Prieto 796, Italia Providencia, C.P. 44648 Guadalajara, Jalisco, México, usted podrá en el momento que lo considere conveniente presentar su solicitud para el limitar o revocar su consentimiento para el tratamiento y/o transferencia de sus datos personales y/o datos sensibles, con fines complementarios, señalados en el presente Aviso de Privacidad.
              </p>


            </Container>
            <Footer />
          </Body>
        </React.Fragment>
      );
    }
  }

  return(<App {...props}/>)
                           
}
)
export default Landing


const Container = styled.div`
width: 70%;
position: relative;
margin: 0 auto;
text-align: left;

`;


const Body = styled.div`
width: 100%;
overflow: hidden;
min-height: 100vh;
position: relative;
background: white;
margin-top: 100px;
@media (max-width: 700px) {
padding-top: 10px; 
}
z-index: 1;
`;
