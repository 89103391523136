import Rebase from "re-base";
import firebase from "firebase";

const base = Rebase.createClass(firebase.database());

export async function getConnectSolicitud(id, callback = null)
{ 
  const data = await base.fetch('connectSolicitudes/' + id, {
    
    then(data){
      if(callback)
        callback(data);
    }
  });
  
  return data
}

export async function getSolicitud(id, callback = null)
{ 
  const data = await base.fetch('solicitudes/'+id, {
    then(data){
      if(callback)
        callback(data);
    }
  });
  
  return data
}

export async function getFullSolicitud(id, callback = null)
{ 
  const data = await base.fetch('solicitudes/'+id, {  
    then(data){
      if(callback)
        callback(data);
      
      return data
    }
  });

  const user = await base.fetch('profile/'+data.usuario, {
  
    then(data){
      if(callback)
        callback(data);
       
      return data
    }
  });

  return {...user, ...data}
}

export async function getSolicitudesFromUser(userId, callback = null){
  const data = await base.fetch('solicitudes', {
    asArray: true,
    queries: {
      orderByChild: 'owner',
      equalTo: userId,
    },  
    then(data){
      if(callback)
        callback(data)
     
    }
  });
  return data
}
