import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";

const _ = require('lodash');

const UserCirlce = styled.div`

  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
`;

const Logo = styled.img`
  padding-top:3px;
  width: 23px;
`;


/*
Don't touch the follow components because are part of the templates
*/

const Nav = styled.nav`
  bottom:0;
  left:0;
  position: fixed;
  overflow: hidden;
  width: 100%;
 background: white;
  color: ${props=>props.theme.default.color.navbarText};
  height: 65px;
  ${props=>props.theme.default.utils.rowContent}
  
  border: solid 1px rgba(0,0,0,0.1);
`;

const NavContent = styled.nav`
  ${props=>props.theme.default.utils.rowContent}
  width: 100%;
  margin: 0 1rem;
     @media (max-width: 700px) {
    margin: 0;
  }
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 0;
    height: 100%;
    flex: 1;
    z-index: 1;
`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

const AlignEnd = styled(_Section)`
    justify-content: flex-end;
    order: 1;
`;

const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  margin: 0 0.5rem;
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}   
           }
        `}
     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}
          @media (max-width: 700px) {
           margin:0 0.5rem;
         }


`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  height: 1.2rem;
  opacity: 0.5;
`;
const Icon = styled.i`
  vertical-align: bottom;
`;
const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  ${props=>props.disabled && `
    opacity:0.4;
  `}
  margin-right: 2rem;
  border-radius: 3px;
  overflow: hidden;
  background: ${props => (!props.transparent ? props.theme.colors.green : "transparent")};
  color: ${props => (!props.transparent ? "white" : "#676C72")};
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  border:0;
${props=>(props.disabled && !props.transparent) && `  
  background:#676C72;
  `}
  @media (max-width: 700px) {
           margin: 0;
         }

`;

const Row = styled.div`
    width: 100%;
   ${props=>props.theme.default.utils.rowContent}
    @media (max-width: 700px) {
    display: block;
  }

`;

const Navigator = styled.nav`
 position:absolute;
 width: 200px;
 left: 50%;
 margin-left -100px;
 height: 65px;
 top:0;
 ${props=>props.theme.default.utils.centerContent}
`;


const NavigatorDot = styled.div`
 width: 15px;
 height: 15px;
 border-radius: 50%;

 display: inline-block;
 margin: 0 0.3rem;
   @media (max-width: 700px) {
          margin: 0 0.25rem;
         }
 
  ${props=> (props.val === props.current) && `
       background: rgba(0, 200, 83, 0.25);
       border: solid 1px ${props.theme.colors.green};

    `}

     ${props=> (props.val < props.current) && `
       background: ${props.theme.colors.green};
       

    `}
     ${props=> (props.val > props.current) && `
        background: rgba(0,0,0,0.2);
      
       

    `}


`;



class Header extends Component
{
  constructor(props) 
  {
    super(props)
    this.state = {
      sidebar: false,
      submit: false
    }
  }

  render() {
    return (   
      <React.Fragment>
        <Nav>
          <NavContent>

            <AlignStart>


              {this.props.current !== 0 &&
                <Item>
                  <Button 
                
                    transparent
                    onClick={this.props.backSlide.bind(this)}
                  >Atrás
                  </Button>
                </Item>

              }

              {this.props.current === 0 &&
                <Item>
                  <Link to="/dashboard">
                    <Button
                
                      transparent>Regresar al inicio</Button>
              
                  </Link>
                </Item>

              }


            

              
            </AlignStart>
            <AlignEnd> 
           
       
              <Item>

                {this.props.current !== 3 &&

                  <Button 
                    disabled={this.props.canContinue}

                    onClick={this.props.nextSlide.bind(this)}
                  >Siguiente
                  </Button>}
                
                {this.props.current === 3 &&

                  <Button 
                    disabled={!this.props.canSubmit}
                              
                    onClick={this.props.continue.bind(this)}
                  >Continuar
                  </Button>}
              </Item>
            </AlignEnd>

            <Navigator>
              <Row>
                <NavigatorDot
                  val={0}
                  current={this.props.current}/>
                <NavigatorDot
                  val={1}
                  current={this.props.current}/>
                <NavigatorDot
                  val={2}
                  current={this.props.current}/>
                <NavigatorDot
                  val={3}
                  current={this.props.current}/>
              </Row>
            </Navigator>

          </NavContent>
             
              
        </Nav>



      </React.Fragment>
    );
  }
}

export default withRouter(Header)