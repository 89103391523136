import React, { Component } from 'react';
import styled from 'styled-components';
import {  Link, withRouter} from 'react-router-dom';
import AddIcon from "react-md-icon/dist/RoundAdd";
import PlayIcon from "react-md-icon/dist/RoundPlayCircleFilled";
import ItemSolicitud from "Components/Molecules/ItemSolicitud"
import Tutorial from "Components/Molecules/Tutorial";
import welcomeImg from "static/img/inicio.png"

const Empty = withRouter((props)=> {

  const Img = styled.img`
  background: transparent;
   width: 250px; 
  @media (max-width: 700px) {
  width: 150px;
  }
`;

  const Container = styled.div`


width: 70%;
margin: 0 auto;
  
 
${props=>props.theme.default.utils.centerContent}
position: relative;
z-index: 3;
     @media (max-width: 700px)
        {
         width: 90%;
        }
`;

  const Title = styled.div`
   font-weight: 500;
   width: 100%;
   font-size: 45px;
   color: #414141;
   margin: 1rem auto;
    @media (max-width: 700px)
        {
        font-size: 35px;
        }
`;


  const Button = styled.div`

  position: relative;
  padding: 0.6rem 1rem;
  height: 180px;
  width:  32%;
  ${props=>props.theme.default.utils.centerContent}
  
  border-radius: 10px;
  overflow: hidden;

  background: ${props => props.color? props.theme.colors.green: props.theme.colors.gray1};
  color: ${props => (props.color ? "white" : "black")};
  cursor: pointer;
  font-weight: Book;
  font-size: 16px;
  border:0;
  a{
  text-decoration: none;
  }
  &:hover{
    box-shadow: 0 0.5px 7px rgba(0,0,0,0.5);
  }

  text-decoration: none;
  z-index: 0;
   ${props=>props.hidden && "opacity:0;"}
        @media (max-width: 700px)
        {
         width: 100%;
         margin: 1rem auto;

        }
`;


  const Row = styled.div`
  width: 100%;
  margin: 0 auto;
  ${props=> props.theme.default.utils.rowContent}

 a{display:contents;
    text-decoration: none;
 }


      @media (max-width: 700px)
        {
         display: block;
        }
`;

  class Loading extends Component 
  {
    constructor(){
      super()
      this.state = {
        tutorial: false,
      }
    }

    render()
    {
      const {status = 0} = this.props.solicitud || {};

      return (
        <Container> 
          {this.props.solicitud?.id ? (
            <ItemSolicitud {...this.props.solicitud} />
          )
            :(
              <React.Fragment>
                <Img src={welcomeImg}/>
                <br/>
                <Title>Bienvenido, inicia aquí y solicita un adelanto</Title>
                <br/>

              </React.Fragment>
            )}
          <Row>
            {(status >= 1 && status <= 4 )? 
              <Button 
                style={{
                  cursor: "not-allowed",
                  background:"rgba(0,0,0,0.5)",
                  color:"rgba(0,0,0,0.4)"
                }}
              >
                <AddIcon
                  style={{
                    fontSize: "40px",

                  }}
      
                />Solicitar Adelanto</Button>

              :<Link to="/create-new">
                <Button color={" "}><AddIcon
                  style={{
                    fontSize: "40px"}}
                />Solicitar Adelanto</Button>
              </Link>}
            <Button 
              onClick={()=>{this.setState({tutorial: true})}}
            > <PlayIcon
              style={{
                  fontSize: "35px",

                }}

              /> <br/>
              Ver Tutorial</Button>
            <Button hidden/>
          </Row>
          {this.state.tutorial &&   <Tutorial close={()=>{this.setState({tutorial: false})}}/>}
        </Container>
    	)
    }
  }
  return (<Loading {...props}/>)

                
}
)

export default Empty