import React, { Component } from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import Close from "react-md-icon/dist/BaselineClose";

class Modal extends Component 
{

  constructor(){
    super()
    this.state = {
      loading: true,
    }
  }

  render()
  {

    const close = this.props.close

    return(
      <FullScreen>
        <PseudofullScreen onClick={close}/>
        <Card>
          <NavBar>
            <Content>
              <AlignStart>
                <Item2>
                  <Title2>
                    Tutorial
                  </Title2>
                </Item2>    
              </AlignStart>
              <AlignEnd>
              </AlignEnd>
            </Content>
            <Close
              onClick={close} 
              style= {{ 
                position: "absolute",
                "top": "1rem",
                "right": "1rem",
                "fontSize":"22px",
                "cursor":"pointer",
                zIndex:"99999999"
              }}
            />
          </NavBar>
          <Container>
            {this.state.loading && <Title>Cargando...</Title>}
            <iframe 
              title="video"
              onLoad={()=>{this.setState({loading: false})}} 
              width="560" 
              height="315" 
              src="https://editor.wideo.co/player?wideoId=25359091548261597821" 
              frameborder="0" 
              allowfullscreen>
            </iframe>
          </Container>
        </Card>
      </FullScreen>
    );
  }
}
      
export default withRouter(Modal)

const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.4);
  padding: 0rem;
  padding-top:1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 80%;
  padding: 1rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
  text-align:center;
 img{
 	width:90%;
 	margin: 0 auto;
 }
 iframe{
  width:100%;
  height:400px;
 }
`;


const NavBar = styled.div`
 position: absolute;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;

  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;


const Container = styled.div`
  width:90%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;






const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;




const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;


const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;




const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;