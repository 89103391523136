import React, { Component } from 'react';
import styled from 'styled-components';

import { Redirect, withRouter} from 'react-router-dom';

import Loading from "Components/Molecules/Loading";

import {getFullSolicitud} from "Files/General/DatabaseQueries/Get/Solicitudes";
import RoundPublic from "react-md-icon/dist/BaselineCheck";
import ConfirmationCode from "Components/Molecules/ConfirmationCode"
import { aceptarSolicitud, cancelarSolicitud} from "Files/General/DatabaseQueries/Post/Solicitudes"
import Close from "react-md-icon/dist/BaselineClose";
import firebase from "firebase";
import solicitudImg from "static/img/lb.png"

const ESTADOS = [
  "Rechazado", // 0 or undefined is cancel
  "En Espera de confirmación", //1
  "Aceptada",
  "En proceso de depósito",
  "Adelanto depositado",
  "Adelanto pagado",
]

const COLORS = [
  "red", // 0 or undefined is cancel
  "#FF8F00", //1
  "green",
  "blue",
  "green",
  "gray",
]

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.error(e)
  }
};




class Modal extends Component 
{
  constructor(props) 
  {
    super(props);
    this.state={
      solicitud: {},
      loading: true,
      redirect: false
    }
  }

 

  async componentDidMount(){
    

    const solicitud = await getFullSolicitud(this.props.id)

    this.setState({solicitud, loading: false})
  }
  
  async aceptarSolicitud(code){
    aceptarSolicitud(this.state.solicitud);
    await firebase.database().ref(`bitacora/${firebase.auth().currentUser.uid}/solicitudes/${this.props.id}`).update({ 
         
      fechaTerminos: Date.now(),
      codigoTerminos: code
    })
      .catch((err)=>{
        this.setState({loading: false})
        alert("Alerta, hubo un error.")       
      });

    this.setState({ redirect: true });
  }


  render()
  {

    const close = this.props.close//?this.props.close:this.props.hideOnClick;
    if (this.state.redirect === true) {
      return <Redirect to="/" />
    }
  
    if(this.state.loading){
      return(
        <Loading text="Obteniendo datos" />
      )
    }
    return(
      <React.Fragment>
        <FullScreen>
          <PseudofullScreen onClick={close}/>
          <Card>
            <NavBar>
              <Content>
                <AlignStart>
                  <Item2 hide>
                    <Row>
                      <Title2>
                        Folio #{this.state.solicitud.folio}
                      </Title2>
                      <Separator />
                    </Row>
        
                  </Item2>
        
        
        
                  <Item2>

                    <PrivacyTab style={{cursor:"not-allowed"}}> 
                      <PrivacyTabContainer>
                        <IconCirlce>
                          <RoundPublic/> 
                        </IconCirlce> 
                        <Subtitle style={{color:COLORS[this.state.solicitud.status]}}> {ESTADOS[this.state.solicitud.status]}
                        </Subtitle>
                      </PrivacyTabContainer>
                    </PrivacyTab>
          
                  </Item2>



                </AlignStart>
    
                <AlignEnd>
      
                </AlignEnd>

              </Content>


              <Close
                onClick={close} 
                style= {{ 
                  position: "absolute",
                  "top": "1rem",
                  "right": "1rem",
                  "fontSize":"22px",
                  "cursor":"pointer"

                }}

              />
            </NavBar>
            <Container>
              <Img src={solicitudImg}  />
              <Title>¡Tu solicitud #{this.state.solicitud.folio} fue aceptada!</Title>
         
              <Subtitle>
                Estás a un paso de recibir tu adelanto, Lee y acepta nuestros términos de adelanto para continuar.
              </Subtitle>

              <h2>Términos del adelanto:</h2>
              <p>1. El monto aprobado de tu solicitud es de ${formatMoney(this.state.solicitud.montoAprobado)} </p>
              <p>2. Tendrás que pagar por tu adelanto la cantidad de ${formatMoney("" +( parseFloat(this.state.solicitud.montoAprobado) + (parseFloat(this.state.solicitud.montoAprobado) *(parseFloat(this.state.solicitud.montoAprobado) === 500?0.15:0.1) ))
              )}  el día {this.state.solicitud.fechaAprobada}.</p>
      
              <p>3.El monto será cargado directamente a tu cuenta de de banco.</p>           

              {/*
            <Payment 
              solicitud={this.state.solicitud}
            />*/}

              <ButtonContainer>

                <Button
                  red 
                  onClick={()=>{
                    cancelarSolicitud(this.state.solicitud.id);
                    this.setState({ redirect: true });  
                  }}
                  shadow>Rechazar
                </Button>

                <Button 
                  onClick={()=>{
                    this.setState({confirm: true})
                  }}
                  shadow>Aceptar condiciones
                </Button>
              </ButtonContainer>
            </Container>
          </Card>
        </FullScreen>
        {this.state.confirm &&   
        
        <ConfirmationCode 
          close={()=>{this.setState({confirm: false})}}
          phone = {this.state.solicitud.telefono}
          continue={(code)=>{
            this.aceptarSolicitud(code);
          }}  
        />}

      </React.Fragment>
    );
  }
}
    
export default withRouter(Modal)


export const UserCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
  display: inline-block;
  margin: 0 0.1rem;
`;

export const IconCirlce = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${props=>props.theme.default.utils.centerContent}
  overflow: none; 
  font-size: 24px;
  background: #DFDFDF;
`;



export const PrivacyTab = styled.button`
 position: relative;
 border: none;
  border-radius: 22px;
  height: 40px;
  font-weight: 400;
  font-size:13px;
  color:#444444;
  background:white;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}

${IconCirlce}{
  
  height: 30px;
  width: 30px;
}
`;

export const PrivacyTabContainer = styled.div`
  height: 75%;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.theme.default.utils.rowContent}
  width:100%;
`;


export const PrivacyText = styled.div`
    margin: 0 0.4rem;
`;




const PseudofullScreen = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: transparent;
  padding: 0rem;
`;

const FullScreen= styled.div`
  position: fixed;
  overflow-y: scroll;
  top:0rem;
  left:0rem;
  right:0rem;
  bottom:0rem;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.8);
  padding: 0rem;
  padding-top:1%;
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
`;

const Card = styled.div`
  margin: 1rem auto;
  border-radius: 7px;
  overflow: hidden;
  width: 50%;
  padding: 1rem 0;
  color: rgba(0,0,0,0.8);
  background: white;
  position: relative;
  text-align: left;
  box-shadow: 0 0.5px 10px rgba(0,0,0,0.3);
  
    @media (max-width: 700px) {
    width: 90%;
    
  }
 
`;


const NavBar = styled.div`
 position: absolute;
width: 100%;
  top:0;
  left:0;
  width: 100%;
  height: 65px;

  background: white;
  box-shadow: 0 0.5px 3px rgba(0,0,0,0.2);
  ${props=>props.theme.default.utils.centerContent}
`;



const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  text-align: center;
  font-size: 25px;
  color: #202124;
`;

const Subtitle = styled.h4`
font-weight: 400;
text-align: center;
color: #5F6469;
font-size: 16px;
padding: 0 0.4rem;
margin: 0.5rem 0;  

`;

const NavTextContainer = styled.div`
  width: 100%;
  text-align: left;

`;

const IconContainer = styled.div`
  position: absolute;
  left: 1rem;
  top: 1rem;
`;

const Icon = styled.i`
  vertical-align: bottom;
  cursor:pointer;
`;

const Img = styled.img`
width: 150px;
margin: 1rem auto;
text-align: center;

`;


const Container = styled.div`
  width:80%;
  margin: 2rem auto;
   padding-top: 65px;
   text-align: center;
`;


const ContainerBox = styled.div`
  width:100%;
  border: 1px gray solid;
  border-radius: 7px;
  margin: 2rem auto;
`;

const ButtonContainer = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 2.3rem;
  text-align: center;

`;

const Button = styled.button`
  position: relative;
  padding: 0.6rem 1rem;
  margin-right: 1rem;
  border-radius: 7px;
  overflow: hidden;
  background: ${props => !props.red?props.theme.colors.green: "none"};
  color: ${props => !props.red?"white": "red"};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  border:${props => props.red?"solid 1px red": "0"};
     @media (max-width: 700px) {
          margin: 1rem auto;
          display: block;
         }

 
`;





const Content = styled.div`
 position: relative;
 width: 100%;
 background: #F8F9FA;
    ${props=>props.theme.default.utils.rowContent}    
    padding: 1rem;
   @media (max-width: 700px) {
          display: inline-block;
         }
`;


const Row = styled.div`
  ${props=>props.theme.default.utils.rowContent}
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;

    min-width: 0;
    height: 100%;

    flex: 1;
    z-index: 1;
    
     @media (max-width: 700px) {
          display: block;
         }

`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
  
`;

const AlignEnd =  styled(_Section)`
    justify-content: flex-end;
    order: 1;
  
`;



const Item = styled.div`
  position: relative; 
  height: 65px;
  ${props=>props.theme.default.utils.centerContent}
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}

  
   @media (max-width: 700px) {
          width: 100%;
         }
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               
               ${props.theme.default.utils.centerContent}
               
           }
          
          
        `}

     ${props=>props.hide && `
        
         @media (max-width: 700px) {
          display: none;
         }
        `}

`;



const Title2 = styled.div`
  margin: 0;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
  color: #202124;
`;

const Subtitle2 = styled.div`
font-weight: 500;
text-align: left;
color: #5F6469;
font-size: 16px;
margin: 0 0.5rem;


`;

const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  margin: 0 0.5rem;
  height: 2rem;
  opacity: 0.5;
`;



const Item2 = styled.div`
  position: relative; 
  ${props=>props.cursor && "cursor: pointer;"}
  ${props=>props.margin && "margin: 0 0.5rem;"}
  text-align: left;

  min-width: 45px; 
  
  
  ${props=>props.hideBig && `
          display: none;
           @media (max-width: 700px) {
               display: inline;
           }      
    `}

     ${props=>props.hide && `
         @media (max-width: 700px) {
          display: none;
         }
      `}
`;
