import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";
import firebase from "firebase"
import Rebase from "re-base"
import Navbar from "./Components/Molecules/Navbar"

const _ = require('lodash');
const Document = withRouter((props)=> {
  const Body = styled.div`
  border-radius: 7px;
  border: solid 1px rgba(0,0,0,0.1);
  overflow: hidden;

`;

  const Container = styled.div`
padding: 1rem;



`;

  class MyComponent extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        item: {},
        loading: true

      }
      this.base = Rebase.createClass(firebase.database());
    }
   
  
    componentDidMount(){

      
      if(firebase.auth().currentUser) 
        this.base.fetch('solicitudes/'+props.id, {
          context: this,
          //asArray: true,
   
      
          then(data){
         
            // ("fetched:", data)
            this.setState({item: data, loading: false})
        
          }
        });
      else
        alert("Hay un error con tu conexion, inicia sesion nuevamente o recarga el navegador.")
     
    }
   
    render(){
      const {item = {id:"0000"}} = this.state
      if(this.state.loading)
        return <h1>Cargando...</h1>

      return(
        <Body>
         
          <Navbar 
            folio={`Folio de adelanto ${item.id.split("-")[0]}`}
          />
         
          <Container>
    
            <h3>Datos de solicitud</h3>
          
            <b>Nombre</b> {item.banco}<br/>
            <b>Clabe</b> {item.clabe}<br/>
            <b>Monto</b> {item.monto}<br/>
            <b>Fecha de soliciud</b> {item.fecha}<br/>
          
            <h3>Datos de Bancarios</h3>
          
            <b>Banco</b> {item.banco}<br/>
            <b>Clabe</b> {item.clabe}<br/>
      
            <br/>
          
            <h3>Archivos</h3>
          
            <a>Identificación.</a>
            <br/>
        
            <a>Comprobante de domicilio.</a>
            <br/>
        
            <a>Comprobante de nomina.</a>
            <br/>
        
            <br/>
            <br/>
            <br/>

          </Container>
          
        </Body>
      )
    }

  }

  return <MyComponent />                
}
)
export default Document