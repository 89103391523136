import React, { Component } from 'react';
import styled from 'styled-components';

import {  Link, withRouter} from 'react-router-dom';
import MorePopover from "./MorePopover"
import Tutorial from "Components/Molecules/Tutorial";
import HelpIcon from "react-md-icon/dist/RoundHelpOutline";
import Sidebar from "Components/Molecules/Sidebar";
import logo from "static/img/logo.png";
import userImg from "static/img/user.png"
class Header extends Component
{
  constructor(props) 
  {
    super(props)
    this.state = {
      sidebar: false,
      tutorial: false,
    }
  }

  render() {
    const tab = this.props.location.pathname
    const notNavbar= this.props.noNavigation
    return (  
      <React.Fragment>  
        <Nav help={this.state.tutorial} >
          <NavContent>
            <AlignStart>
              {this.props.auth && <Item
                hideBig
                margin>
                <div onClick={()=>{
                  this.setState({sidebar:true})}}>
                  <i className="material-icons">menu</i>
                </div>
              </Item>}

              <Item margin >
                <Link to="/dashboard">
                  <Logo  src={logo}/>
                </Link>
              </Item>
              <Item 
                hide 
                style={{ 
                  fontSize:"22px", 
                  fontWeigth:"500",
                  marginRight:"1rem",

                }}>
                <Link to="/dashboard">
                  <LogoTitle>
                    Adelanta-T
                  </LogoTitle>
                </Link>
              </Item>


                 
              {!notNavbar &&
                <React.Fragment>
                  <TabItem
                  
                  
                    hide
                    margin 
                    active = {tab === ("/dashboard")}
                    style={{ 
                  
                      fontSize:"18px", 
                      fontWeigth:"500"
                    }}>
                    <Link to="/dashboard">
                      Inicio
                    </Link>
                  </TabItem>
                  
                  <TabItem
                  
                    hide
                    margin
                    active = {tab === ("/dashboard/solicitudes")}
                    style={{ 
                      fontSize:"18px", 
                      fontWeigth:"Book"
                    }}> <Link to="/dashboard/solicitudes">
                      Solicitudes </Link>
                  </TabItem>
                  
                  <TabItem
                  
                    hide
                    active = {tab === ("/dashboard/archivos")} 
                    margin 
                    style={{ 
                      fontSize:"18px", 
                      fontWeigth:"Book"
                    }}> <Link to="/dashboard/archivos">
                      Archivos</Link>
                  </TabItem>
                </React.Fragment>
              }
            </AlignStart>

            <AlignEnd> 

              <Item>
                <HelpIcon
                  onClick={()=>{this.setState({tutorial: true})}}
                  style={{ 
                    textDecoration:"none",
                    color:"#00C853",
                    fontSize:"24px",    
                  }} />

              </Item>
 
              <Item >
                {!this.props.auth ? 
                  (
                    <Link 
                      style={{ 
                        textDecoration:"none",
                        color:"gray"
                      }}
                      to="/login">
                      Iniciar sesión
                    </Link>
                  )
                  : 
                  (
                    <MorePopover>
                      <UserCirlce src={userImg}/>
                    </MorePopover>
                  )
                }
              </Item>
           
            </AlignEnd>

          </NavContent>

      
  
        </Nav>  
        {this.state.sidebar && 
        <Sidebar close={()=>{
          this.setState({sidebar:false})
        }}
        />
        }

        {this.state.tutorial &&   
        <Tutorial close={()=>{
          this.setState({tutorial: false})}}/>
        }

      </React.Fragment>
    );
  }
}

export default withRouter(Header)




const UserCirlce = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  margin: 0 1rem;

`;


const LogoTitle = styled.div`

    font-size: 22px;
    
`;





const Logo = styled.img`
    padding-top:3px;
    width: 23px;
    margin-left: -10px;
`;

const Nav = styled.nav`
    top:0;
    left:0;
    position: fixed;
    overflow: hidden;
    width: 100%;
    background: white;
    color: ${props=>props.theme.default.color.navbarText};
    height: 60px;
    ${props=>props.theme.default.utils.rowContent}
    z-index: 1;
    box-shadow: 0 0.5px 2px rgba(0,0,0,0.2);
   
`;

const NavContent = styled.nav`
    ${props=>props.theme.default.utils.rowContent}
    width: 100%;
    margin: 0 5%;
     @media (max-width: 700px) {
   margin: 0 5%;
  }
`;

const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 0;
    height: 100%;
    flex: 1;
    z-index: 1;
`;

const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

const AlignEnd = styled(_Section)`
    justify-content: flex-end;
    order: 1;
`;

const Item = styled.div`
    position: relative; 
    height: 60px;
    color:${props=> props.active?"black":"#5F6469" };
    ${props=>props.theme.default.utils.centerContent}
    ${props=>props.cursor && "cursor: pointer;"}
    ${props=>props.margin && "margin: 0 0.5rem;"}
    ${props=>props.hideBig && `
        display: none;
        @media (max-width: 700px)
        {
            ${props.theme.default.utils.centerContent}
        }
    `}
    ${props=>props.hide && `
        @media (max-width: 700px)
        {
          display: none;
        }
    `}
    a{
        text-decoration: none;
        color:${props=> props.active?"black":"#5F6469" };
        font-size: 18px;
    }
`;

 
const TabItem = ({children, active, onClick, hide})=>{
  const TabSelector = styled.div`
    background: ${(props)=>props.theme.colors.green};
    position: absolute;
    width: 65%;
    height: 4px;
    font-size: 16px;
    border-radius: 5px 5px 0 0;
    bottom: 0;
    left:17.5%;
`;

  return (
    <Item 
      style={{margin:"0 0.8rem"}}
      onClick={onClick} 
      active={active}
      hide={hide}
      cursorPointer
    >
      {children}
      {active &&  <TabSelector/>}
    </Item>
  )
}
