
import React, { Component } from 'react';
import styled ,{keyframes, css, ThemeProvider}from 'styled-components';
import ReactDOM from 'react-dom';
import { Redirect, Link,BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import { connect, Provider } from "react-redux";
import { combineReducers, applyMiddleware, createStore, bindActionCreators  } from "redux";

		 //Imports.
//{getImports(data.component.imports)}
   

		

const _ = require('lodash');

const Navbar = withRouter((props)=> {


  //Code
  const UserCirlce = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.src? "url(" +props.src+ ")": "url('http://placehold.it/50x50')"};
`;

  const Logo = styled.img`
    padding-top:3px;
    width: 23px;
    margin-left: -10px;
`;

  const Nav = styled.nav`
    top:0;
    left:0;
   
    overflow: hidden;
    width: 100%;
    background: white;
    color: black;
    height: 55px;
    ${props=>props.theme.default.utils.rowContent}
    z-index:99999999;
    border: solid 1px rgba(0,0,0,0.1);
`;

  const NavContent = styled.nav`
    ${props=>props.theme.default.utils.rowContent}
    width: 100%;
    margin: 0 1rem;
`;

  const _Section = styled.div`  
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 0;
    height: 100%;
    flex: 1;
    z-index: 1;

`;

  const AlignStart = styled(_Section)`
    justify-content: flex-start;
    order: -1;
`;

  const AlignEnd = styled(_Section)`
    justify-content: flex-end;
    order: 1;
`;

  const Item = styled.div`
    position: relative; 
    height: 55px;
    min-width: 45px; 
    ${props=>props.theme.default.utils.centerContent}
    ${props=>props.cursor && "cursor: pointer;"}
    ${props=>props.margin && "margin: 0 0.5rem;"}
    ${props=>props.hideBig && `
        display: none;
        @media (max-width: 700px)
        {
            ${props.theme.default.utils.centerContent}
        }
    `}
    ${props=>props.hide && `
        @media (max-width: 700px)
        {
          display: none;
        }
    `}
    a{
        text-decoration: none;
    }
`;
  const Separator = styled.div`
  color: #757575;
  position: relative;
  border-left: 1px solid #757575;
  width: 2px;
  height: 1.2rem;
  opacity: 0.5;
`;
  const Icon = styled.i`
  vertical-align: bottom;
`;

  const Title = styled.div`
font-size: 22px;
color: #313033;

`;

  const Subtitle = styled.div`
font-size: 22px;
color: #313033;

`;

  class Header extends Component
  {
    constructor(props) 
    {
      super(props)
      this.state = {
       
      }
    }
  

    render() {
      const {folio = "Folio de n asad2", status= "En proceso", color = '#FF960D '} = props

      return (    
        <Nav>
          <NavContent>
            <AlignStart>
              <Item >
                <Title>
                  {folio}
                </Title>
              </Item>

              
              <Item  
                style={{ 
                  fontSize:"14px",
                  marginLeft: "1rem",
                  color,
                }}>
              
                {status}
                    
             
              </Item>
            </AlignStart>

            <AlignEnd> 
              <Item >
     
                <Icon className="material-icons">settings</Icon>
            

              </Item>
              
              
              <Item >
     
                <Icon className="material-icons">add</Icon>
              </Item>
             
             
              <Item >
     
                <Icon className="material-icons">print</Icon>
     
              </Item>
              
            </AlignEnd>

          </NavContent>
        </Nav>
      );
    }
  }

  return(<Header/>)
                
}
)

        


export default Navbar