const _ = require('lodash');
const BANKS = [].concat(_.map(["BANAMEX",
  "BANCOMEXT",
  "BANOBRAS",
  "BBVA BANCOMER",
  "SANTANDER",
  "BANJERCITO",
  "HSBC",
  "BAJIO",
  "IXE",
  "INBURSA",
  "INTERACCIONES",
  "MIFEL",
  "SCOTIABANK",
  "BANREGIO",
  "INVEX",
  "BANSI",
  "AFIRME",
  "BANORTE",
  "THE ROYAL BANK",
  "AMERICAN EXPRESS",
  "BAMSA",
  "TOKYO Bank",
  "JP MORGAN",
  "BMONEX",
  "VE POR MAS",
  "ING",
  "DEUTSCHE",
  "CREDIT SUISSE",
  "AZTECA",
  "AUTOFIN",
  "BARCLAYS",
  "COMPARTAMOS",
  "BANCO FAMSA",
  "BMULTIVA",
  "ACTINVER",
  "WAL-MART",
  "NAFIN",
  "INTERBANCO",
  "BANCOPPEL",
  "ABC CAPITAL",
  "UBS BANK",
  "CONSUBANCO",
  "VOLKSWAGEN",
  "CIBANCO",
  "BBASE",
  "BANSEFI",
].sort(),(item)=>{return {value: item, title: item}}))

BANKS.push({value:"none", title:"Selecciona el nombre de tu banco."})

export default BANKS;